import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
// import freeze from 'redux-freeze'
import _ from 'lodash'

import rootReducer from './rootReducer'

const middlewares = _.compact([thunk])
// const middlewares = _.compact([thunk, logger])
const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)

const store = createStore(rootReducer, undefined, composedEnhancers)

export default store
