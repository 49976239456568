import styled from 'styled-components'
import { NeuTab } from '@neutron/react'
import { NavLink } from 'react-router-dom'

export const WrapperForDisabledClick = styled.div`
  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`

export const StyledHeader = styled.div`
  width: 100%;
  font-size: 14px !important;
  padding: 2px;
  height: 63.9844px !important;
  line-height: 19.999px !important;
  padding: 0px !important;
  background-color: #03173e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .projectTitle {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 20px;
    user-select: none;
    color: white;
    display: flex;
    flex-wrap: no-wrap;
  }
  .navItemText {
    color: white;
  }
`

export const Help = styled.div.attrs({
  className: 'Help-Button-Responsive cursor',
})`
  font-size: 14px;
  margin-left: 15px;
  color: white;
  display: flex;
  padding-top: 10px;
`

export const TabButton = styled(NeuTab)`
  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`
export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13.5px;
  margin-right: 25px;
  color: #ffffff;
  input {
    color: #ffffff;
  }
`
export const StyledNavLink = styled(NavLink)`
  background-color: transparent; /* or whatever your normal background color is */
  &:hover {
    background-color: transparent;
  }
`
