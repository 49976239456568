import styled from 'styled-components'
import { NeuButtonSelector, NeuButton, NeuTab } from '@neutron/react'

export const ReportsSubNav = styled(NeuTab).attrs({
  className: 'neu-text-slate',
})`
  font-weight: lighter !important;
  background-color: transparent !important;
  :hover {
    background-color: white;
    color: #0085ca;
    font-weight: bold;
    text-decoration: none;
    --tab-hover-border: #0085ca;
  }
`

export const SubNavItem = styled.div.attrs({
  className: 'SubNavItem-Responsive neu-text-tag fw-bold neu-text-slate',
})`
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: flex-start;
  height: 30px;
  ${({ disabled }) => (disabled ? 'display: none' : 'display: in-line')}
`

export const NavItemText = styled.span`
  display: block;
  background-color: transparent;
  font-family: 'HCA-Mark', 'Arial', sans-serif;
  font-size: 14px;
  color: #1f2532;
  line-height: 22px;
  vertical-align: middle;
  text-decoration: none;
  font-weight: 400;
  width: auto;
  :hover {
    color: #0085ca;
    font-weight: bold;
  }
`
export const TabButton = styled.button`
  background-color: transparent;
  display: inline;
  height: 60px;
  font-size: 16px !important;
  padding: 0px 10px;
  margin: 0px;
  border: none;
  border-bottom: 2px solid transparent;
  min-width: 62px;
  max-width: 250px;
  font-family: 'HCA-Mark', 'Arial', sans-serif !important;
  text-align: center;
  list-style: none;
  vertical-align: middle;
  white-space: unset;
  cursor: pointer;
  opacity: 0.7;
  margin-left: 25px;
  :hover {
    color: #0085ca;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    border-color: #0085ca;
  }
`

export const SubNavButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const AssignActionButton = styled(NeuButton)`
  font-size: 16px !important;
  padding: 0 15px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  height: 40px !important;
  box-sizing: border-box !important;
  width: 95px !important;
  max-width: 300px !important;
  border: none !important;
`

export const AssignmentActionButtons = styled(NeuButton)`
  padding: 0 15px !important;
  margin: 8px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  height: 40px !important;
  box-sizing: border-box !important;
  max-width: 300px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: 'HCA-Mark', 'Arial', sans-serif !important;
  font-weight: bold !important;
  transition: 0.5s ease-in-out !important;
  border: none !important;
  :hover {
    cursor: pointer !important;
    background-color: white !important;
    ${({ disabled }) => disabled && `cursor: default !important;`}
    ${({ color, disabled }) => !disabled && `color: ${color} !important;`}
  }
  ${({ disabled }) =>
    !disabled ? `display: in-line !important;` : `display:none !important`};
  ${({ color, disabled }) =>
    !disabled && color === '#005589'
      ? `color: white !important; background-color: ${color} !important;`
      : `color: ${color} !important;`};
}
`
export const DraftButtons = styled(NeuButtonSelector)`
  color: ${({ draftButtonSelected }) =>
    draftButtonSelected ? 'white !important' : '#005589 !important'};
  background-color: ${({ draftButtonSelected }) =>
    draftButtonSelected ? '#005589 !important' : 'white !important'};
  font-family: 'HCA-Mark', 'Arial', sans-serif !important;
  font-weight: bold !important;
  border: ${({ draftButtonSelected }) =>
    draftButtonSelected ? 'none !important' : '3px solid #005589 !important'};
  :hover {
    cursor: pointer;
    color: ${({ draftButtonSelected }) =>
      draftButtonSelected ? 'white !important' : '#005589 !important'};
    background-color: ${({ draftButtonSelected }) =>
      draftButtonSelected ? '#005589 !important' : 'white !important'};
  }
`
export const PublishButton = styled.div`
  display: flex;
  height: 40px;
  margin-top: 5px;
  // background-color: rgba(190,85,46,0);
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  padding: ${({ publishing }) => (publishing ? '12px 38px' : '12px 15px;')}
  font-family: 'HCA-Mark', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  line-height: 15px;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items:center;
  ${({ backgroundColor, color, disabled }) => {
    if (!disabled) {
      return `color: ${color} !important;
            background-color: ${backgroundColor};
            display:in-line;`
    } else {
      return `display: none;`
    }
  }};
`

export const SubNav = styled.div`
  height: ${(props) =>
    props.page === 'manage' || props.page === 'reports' ? '60px' : '84px'};
  background-color: white;
  padding-bottom: 28px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  .is-active {
    color: #0085ca !important;
    font-weight: bold;
    border-bottom-color: #0085ca !important;
    :hover {
      color: #0085ca !important;
      font-weight: bold;
    }
    :active {
      color: #0085ca !important;
      font-weight: bold;
    }
  }
`
