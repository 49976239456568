/**
 * External Imports
 * */
import { NeuIcon } from '@neutron/react'
/**
 * Internal Imports
 */
import { AddButton, BackButton } from './searchbar.styles'

const SearchBar = ({
  selectedSite,
  dataType,
  selectedUnit,
  selectedRoom,
  path,
  userPermissions,
  handleBackButton,
  searchText,
  handleSearch,
  dispatch,
  authToken,
  clinicalRoles,
  adminFacilityCoid,
}) => {
  let backToPageText
  if (selectedSite) {
    if (dataType === 'Units') backToPageText = 'Sites'
    if (!selectedUnit) {
      if (dataType === 'Rooms') backToPageText = 'Sites'
      if (!selectedRoom && dataType === 'Beds') backToPageText = 'Sites'
      if (selectedRoom && dataType === 'Beds') backToPageText = 'Rooms'
    } else {
      if (dataType === 'Rooms') backToPageText = 'Units'
      if (!selectedRoom && dataType === 'Beds') backToPageText = 'Units'
      if (selectedRoom && dataType === 'Beds') backToPageText = 'Rooms'
    }
  }
  let searchBarText
  if (path === 'locations') {
    searchBarText = dataType
  } else {
    searchBarText = path.charAt(0).toUpperCase() + path.slice(1)
  }

  let addButtonText = ''
  const showAddButton =
    (path === 'users' && userPermissions.includes('CreateUser')) ||
    (path === 'locations' &&
      dataType !== 'Sites' &&
      userPermissions.includes('CreateLocations'))
  if (path !== 'users') {
    if (
      (selectedRoom && dataType === 'Beds') ||
      (selectedUnit && dataType === 'Rooms') ||
      dataType === 'Units'
    ) {
      addButtonText = `Add ${dataType.slice(0, dataType.length - 1)}`
    } else {
      addButtonText = ''
    }
  } else {
    addButtonText = 'Add User'
  }
  return (
    <>
      {path === 'locations' && dataType !== 'Sites' && (
        <BackButton
          kind="tertiary"
          onClick={() => handleBackButton(backToPageText)}
          icon="chevron_left"
        >
          <div style={{ textWrap: 'nowrap' }}>Back to {backToPageText}</div>
        </BackButton>
      )}
      <div style={{ display: 'flex', width: '90%' }}>
        <div className="pl3 mt2" style={{ width: '85%' }}>
          <div
            className="ui left icon input Patient-Search w-100"
            style={{ minLength: '1054px', minHeight: '38px' }}
          >
            {path !== 'users' && (
              <input
                type="text"
                style={{ width: '100%', paddingLeft: '40px' }}
                placeholder={`Search ${searchBarText}`}
                value={searchText}
                onChange={(event) => {
                  const sanitizedSearchText = event.target.value
                  handleSearch(sanitizedSearchText)
                }}
              />
            )}
            {path === 'users' && (
              <input
                type="text"
                style={{ width: '100%', paddingLeft: '40px' }}
                placeholder={`Search ${searchBarText}`}
                onChange={(event) => {
                  const sanitizedSearchText = event.target.value
                  handleSearch(sanitizedSearchText)
                }}
              />
            )}
            <NeuIcon
              color="gray"
              fill={true}
              weight={300}
              size="1x"
              style={{
                marginLeft: 1,
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              search
            </NeuIcon>
          </div>
        </div>

        {showAddButton && addButtonText !== '' ? (
          <AddButton
            color="primary-70"
            fill="flat-white"
            onClick={() => {
              if (path === 'users') {
                dispatch({
                  type: 'ui/MANAGE_SLIDING_PANE',
                  payload: {
                    open: true,
                    source: 'add-user-button',
                    data: {
                      authToken: authToken,
                      clinicalRoles: clinicalRoles,
                      selectedSite: selectedSite,
                    },
                  },
                })
              } else {
                if (adminFacilityCoid !== 'All') {
                  dispatch({
                    type: 'ui/MANAGE_SLIDING_PANE',
                    payload: {
                      open: true,
                      source: 'add-location',
                      data: {
                        authToken: authToken,
                        clinicalRoles: clinicalRoles,
                        selectedSite: selectedSite,
                        dataType: dataType,
                      },
                    },
                  })
                }
              }
              dispatch({
                type: 'ui/FLASH_MESSAGE',
                payload: { messages: [], type: '', uuid: '' },
              })
            }}
          >
            <div style={{ width: '92px' }}>
              <NeuIcon style={{ marginBottom: '2px' }}>add</NeuIcon>
              {addButtonText}
            </div>
          </AddButton>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default SearchBar
