import jsPDF from 'jspdf'
import moment from 'moment'

export default function (data, { template, breadcrumb, fileName }, columns) {
  // Declare instance of jsPDF
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'l',
    unit: 'pt',
    format: 'letter',
  })
  // Declare standard units of measurement
  const d = {
    inch: 72,
    margin: 23,
    h1: 12,
    h2: 9,
    fontSize: 7.5,
    gray: [71, 71, 84],
    lightgray: 200,
    padding: 3,
    bRadius: 3,
    stroke: 1,
    gutter: 3,
    get pageHeight() {
      return this.inch * 8.5
    },
    get pageWidth() {
      return this.inch * 11
    },
    get liveAreaWidth() {
      return this.pageWidth - this.margin * 2
    },
    get liveAreaHeight() {
      return this.pageHeight - this.inch - this.margin
    },
    get column() {
      return Math.round(this.liveAreaWidth / 12)
    },
    get lineHeight() {
      return this.h1 + this.padding
    },
    get columnStart() {
      return this.margin
    },
  }

  // Declare layout standards
  function colHeaders(headers) {
    // headers will be an array of objects
    // key and multiplier

    const headerRow = []

    headers.forEach((header, index) => {
      headerRow.push({
        col_header: header.label,
        get col_w() {
          return header.key === 'notes'
            ? (d.liveAreaWidth / (headers.length + 1)) *
                (headers.length - (index - 1))
            : colWidth(d.liveAreaWidth / (headers.length + 1), 1)
        },
        get col_x() {
          if (index === 0) {
            return d.columnStart
          } else {
            return cols[index - 1].col_x + cols[index - 1].col_w
          }
        },
      })
    })

    return headerRow
  }

  const cols = colHeaders(template.headers)

  // Functions
  function colWidth(base, multiplier) {
    return base * multiplier
  }

  const setPadding = (n) => n * d.padding

  const generateCell = (string, column, color, y, height) => {
    const columnStart = column.col_x
    const columnWidth = column.col_w
    const fontSize = d.fontSize

    const lines = doc
      .setDrawColor(color)
      .setFontSize(fontSize)
      .setFont('MarkforHCA-Regular', 'normal')
      .splitTextToSize(string, columnWidth - setPadding(3))
    const textY = (lines.length * doc.getLineHeight()) / 2
    const centerH = height / 2

    return doc
      .rect(columnStart, y, columnWidth, height, 'S')
      .text(
        lines,
        columnStart + setPadding(2),
        y + (centerH - textY) + setPadding(2),
      )
  }

  const generateMasthead = (str, size, r, g, b, x, y) =>
    doc
      .setFontSize(size)
      .setFont('MarkforHCA-Bold', 'bold')
      .setTextColor(r, g, b)
      .text(x, y, str)

  const genDate = (str, fill, size) =>
    doc
      .setFillColor(fill)
      .setFontSize(size)
      .setFont('MarkforHCA-Bold', 'bold')
      .text(d.pageWidth - d.margin * 5, d.margin + d.h1, str)

  const generateBreadcrumb = (str, size, r, g, b, x, y) =>
    doc
      .setFontSize(size)
      .setFont('MarkforHCA-Regular', 'normal')
      .setTextColor(r, g, b)
      .text(x, y, str)

  const generateColumnHeaders = (arr, y) =>
    arr.map((i) =>
      doc
        .setFont('MarkforHCA-Bold', 'bold')
        .text(i.col_x + setPadding(1), y, i.col_header),
    )

  const generatePageHeader = (str) => {
    generateMasthead(
      template.heading,
      d.h1,
      ...d.gray,
      d.margin,
      d.margin + d.h1,
    )
    genDate(`${moment().format('MM/DD/YYYY HH:mm:ss')}`, d.lightgray, d.h2)
    generateBreadcrumb(
      `${template.subHeading}${str}`,
      d.h2,
      ...d.gray,
      d.margin,
      d.margin + setPadding(2) + d.h1 + d.h2,
    )
    generateColumnHeaders(cols, setPadding(22))
  }

  const calcRowHeight = (row) => {
    let lines = 0

    row.forEach((c) => {
      const columnLines = doc.splitTextToSize(c, cols[2].col_w)
      lines = Math.max(columnLines.length, lines)
    })
    return lines
  }

  for (var h = 0; h < data.length; h++) {
    let yPos = setPadding(25)
    // Add new header for each new object
    generatePageHeader(data[h].unit)
    for (var i = 0; i < data[h].unitData.length; i++) {
      let row = data[h].unitData[i]
      let height = calcRowHeight(row) * doc.getLineHeight()
      const rowHeight = height + 10

      // Add cell data for each column (left to right)
      for (var j = 0; j < cols.length; j++) {
        const column = cols[j]
        const str = row[j]

        if (yPos >= d.liveAreaHeight) {
          // Continuing data
          doc.addPage()
          generatePageHeader(data[h].unit)
          yPos = setPadding(24)
          generateCell(str, column, d.lightgray, yPos, rowHeight)
        } else {
          generateCell(str, column, d.lightgray, yPos, rowHeight)
        }
      }
      yPos += rowHeight
    }
    // To remove ending blank page
    if (h !== data.length - 1) doc.addPage()
  }
  doc.save(fileName)
}
