/**
 * External Imports
 */
import styled from 'styled-components'

export const Container = styled.div`
  width: calc(100vw - 280px);
  min-width: 1000px;
  display: flex;
  background-color: white;
  flex-direction: column;
  -webkit-box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
`

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  padding: 10px;
`
