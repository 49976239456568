import styled from 'styled-components'
import { NeuButton } from '@neutron/react'

export const AddButton = styled(NeuButton)`
  width: 124px !important;
  margin-left: 3px !important;
  margin-top: 1px !important;
  height: 38px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none !important;
  color: #206da3 !important;
  white-space: nowrap;
  &:hover {
    background-color: #cdd1d9 !important;
  }
`

export const BackButton = styled(NeuButton)`
  width: 155px !important;
  length: 40px !important;
  margin-left: 15px !important;
  margin-top: 2px !important;
  margin-bottom: 20px !important;
  height: 38px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none !important;
  color: #206da3 !important;
  &:hover {
    background-color: #cdd1d9 !important;
  }
`
