import moment from 'moment'
import _ from 'lodash'

export default function formatSlidingPaneData(userGroups, cellData) {
  const chips = []
  let locationName, patientName, patientDOB, patientGender, patientMRN

  locationName = cellData.location.displayName || ''
  patientName =
    (cellData.patient &&
      `${cellData.patient.lastName}, ${cellData.patient.firstName}`) ||
    'Empty'
  patientDOB =
    moment(cellData.patient && cellData.patient.dateOfBirth).format(
      'MM/DD/YYYY',
    ) || ''

  patientGender =
    (cellData.patient &&
      cellData.patient.gender &&
      cellData.patient.gender[0]) ||
    ''
  patientMRN = (cellData.patient && cellData.patient.medicalRecordNumber) || ''

  cellData.patient &&
    cellData.patient.assignments &&
    cellData.patient.assignments.forEach((assignment) => {
      chips.push({
        key: assignment.id,
        abbreviation: assignment.assignedSlot.abbreviation,
        systemName: assignment.assignedSlot.systemName,
        userAssigned: `${
          assignment.user.firstName
        } ${assignment.user.lastName.charAt(0)}.`,
        fullName: `${assignment.user.firstName} ${assignment.user.lastName}`,
      })
    })
  cellData.location.assignments &&
    cellData.location.assignments.forEach((assignment) => {
      chips.push({
        key: assignment.id,
        abbreviation: assignment.assignedSlot.abbreviation,
        systemName: assignment.assignedSlot.systemName,
        userAssigned: `${
          assignment.user.firstName
        } ${assignment.user.lastName.charAt(0)}.`,
        fullName: `${assignment.user.firstName} ${assignment.user.lastName}`,
      })
    })

  const groupNames = Object.keys(userGroups)
  const groupedChips = groupNames.map((group) => {
    const data = chips.filter((chip) =>
      userGroups[group].some((name) => name.systemName === chip.systemName),
    )
    return {
      label: group,
      data,
    }
  })

  const finalData = {
    headerData: {
      locationName,
      patientName,
      patientDOB,
      patientGender,
      patientMRN,
    },
    data: [],
  }

  groupedChips.forEach((group) => {
    if (group.data.length > 0) finalData.data.push(group)
  })

  const alwaysFirst = finalData.data.filter((data) => data.label === 'Nursing')

  const dataWithoutNursing = finalData.data.filter(
    (data) => data.label !== 'Nursing',
  )

  const ordered = [...alwaysFirst, ..._.orderBy(dataWithoutNursing, 'label')]

  return Object.assign({}, finalData, {
    data: ordered,
  })
}
