import styled from 'styled-components'
import { NeuButton} from '@neutron/react'

// reports

export const Print = styled(NeuButton)`
  font-size: 0.9em !important;
  margin-top: 0px !important;
  display: flex;
  border: solid red 3px !important;
`
export const DropShadowDiv = styled.div`
  -webkit-box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 20px 8px -20px rgba(0, 0, 0, 0.3);
  background-color: white;
  min-height: 1000px;
  max-height: 100%;
  display: flex;
  flex: 1;
`

// reportsList

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #bcbcbc;
  border-bottom: ${(props) => (props.last ? '1px solid #bcbcbc' : '')};
`
export const IsolationWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`