import styled from 'styled-components'

export const IsolationStatusDiv = styled.div`
  background-color: #5c6ab8;
  height: 24px;
  min-width: 24px;
  width: max-content;
  text-align: center;
  align-content: center;
  padding: 2px;
  color: white;
  margin-left: 3px;
  border-radius: 5px;
`
