/**
 * Style Imports
 */

import { NeuIcon } from '@neutron/react'
import { Snackbar } from './nursingAssignmentWarning.styles'

const NursingAssignmentWarning = () => {
  return (
    <Snackbar>
      <div className="align-items-center mt-2">
        <NeuIcon
          color="plain-100"
          fill={true}
          size="2x"
          className=""
          style={{ marginRight: '5px' }}
        >
          info
        </NeuIcon>
        Direct care nurses should use Location Assignment tab.
      </div>
    </Snackbar>
  )
}
export default NursingAssignmentWarning
