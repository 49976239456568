/**
 * External Imports
 * */
import { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { connect, useSelector } from 'react-redux'
import { NeuIcon, NeuTooltip } from '@neutron/react'
import { CSVLink } from 'react-csv'
/**
 * Internal Imports
 * */
import SubNavigation from '../headers/subNav/SubNav'
import ReportsTable from './reportsTable'
import Loading from '../reusableComponents/neutronComponents/Spinner'
import SlidingPane from '../reusableComponents/SlidingPane'
import { GenericErrorSnackbar } from '../reusableComponents/errorPages/ErrorSnackbar'
import Checkbox from '../reusableComponents/neutronComponents/checkbox/checkbox'

import pdfReport from '../../utils/pdf/report'
import formatReports from '../../utils/formatReports'
import formatSnapshot from '../../utils/formatSnapshot'
import generateMockReport from '../../utils/mockReport'
import {
  formatAssignmentAuditReport,
  formatSnapshotData,
  formatMissingAssignmentReport,
} from '../../utils/formatReports'
import { filterByKeyword } from '../../utils/keywordSearch'

import { usePrevious } from '../../utils/CustomHooks'
/**
 * Style Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import { DropShadowDiv } from './reports.styles'
import { CSVPrint, PDFPrint } from '../reusableComponents/print/print.styles'

const Reports = ({
  ui,
  dispatch,
  user,
  sites,
  selectedSiteId,
  user34,
  authToken,
  positions: propPositions,
  snapshot,
  fetchingReport,
  missingAssignments,
  assignmentAudit,
  selPage,
  units,
  assignmentAuditData,
  currentPath,
}) => {
  const [hideDischargedPatients, setHideDischargedPatients] = useState(false)
  const [slidingPaneVisible, setSlidingPaneIsVisible] = useState(true)

  // Report Sliding Pane: Slide In/Out
  const reportsSlidingPaneOpen = useSelector(
    (state) => state.ui.reportSlidingPane.open,
  )
  const [reportPaneState, setReportPaneState] = useState(reportsSlidingPaneOpen)
  const showPane = reportPaneState

  useEffect(() => {
    if (reportsSlidingPaneOpen) {
      setReportPaneState(true)
    } else {
      const timer = setTimeout(() => {
        setReportPaneState(false)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [reportsSlidingPaneOpen])

  window.addEventListener('click', (e) => {
    if (
      ui.flashMessage.messages.length > 0 &&
      e.target.className !== 'snackbar'
    ) {
      dispatch({
        type: 'ui/FLASH_MESSAGE',
        payload: {
          messages: [],
          type: '',
          uuid: '',
        },
      })
    }
  })

  const authorizedGlobalAdmin =
    user.authorizationRoles[0].displayName === 'Global Admin'
  const authorizedLocalAdmin =
    user.authorizationRoles[0].displayName === 'Local Admin'

  const site = sites.sites.filter((site) => site.siteId === selectedSiteId)[0]
    .displayName

  const nursingPositions = []
  const formattedNonNursingPositions = []
  propPositions.forEach((position) => {
    if (position.roleGroup === 'Nursing') {
      position.assignmentSlots.forEach((slot, index) => {
        nursingPositions.push({
          key: slot.systemName,
          value: slot.systemName,
          text: slot.abbreviation,
        })
      })
    } else {
      position.assignmentSlots.forEach((slot, index) => {
        formattedNonNursingPositions.push({
          key: slot.systemName,
          value: slot.systemName,
          text: slot.abbreviation,
        })
      })
    }
  })
  const alphabetizedPositions = _.sortBy(
    formattedNonNursingPositions,
    (o) => o.text,
  )
  const allFormattedPositions = nursingPositions.concat(alphabetizedPositions)

  //TODO create path for each reporting link as in manage
  const path = window.location.pathname.replace('/reports/', '')

  let reportingData
  let positions

  const previousPath = usePrevious(currentPath)

  if (path === 'snapshot') {
    reportingData =
      snapshot.length > 0 && fetchingReport === false
        ? formatSnapshotData(snapshot, ui.snapshotData.position)
        : []
    positions = allFormattedPositions
  } else if (path === 'missingassignments') {
    const filteredIfDischargedPatient = hideDischargedPatients
      ? missingAssignments.filter(
          (assignment) => !assignment.isCurrentlyDischarged,
        )
      : missingAssignments

    reportingData =
      missingAssignments.length > 0
        ? formatMissingAssignmentReport(filteredIfDischargedPatient, site)
        : []

    positions = nursingPositions
  } else {
    reportingData =
      assignmentAudit.length > 0
        ? formatAssignmentAuditReport(assignmentAudit, allFormattedPositions)
        : []
    positions = allFormattedPositions
  }

  const { report, headers } =
    fetchingReport === false
      ? formatReports(filterByKeyword(reportingData, ui.modifyReportKeyword))
      : []
  const reportHasData = report && report.length > 0
  const handleSlidingPane = (data) => {
    setSlidingPaneIsVisible(!slidingPaneVisible)
    dispatch({
      type: 'ui/REPORTS_SLIDING_PANE',
      payload: {
        open: true,
        source: selPage,
        data: data,
      },
    })
    dispatch({ type: 'ui/CLEAR_MISSING_ASSIGNMENT_DATA' })
    dispatch({ type: 'ui/CLEAR_AUDIT_TYPE_AND_TIME' })
    dispatch({ type: 'ui/CLEAR_SNAPSHOT_DATE_AND_TIME' })
    dispatch({ type: 'reports/CLEAR_REPORT_DATA' })
    dispatch({ type: 'ui/CLEAR_REPORT_KEYWORD' })
  }
  const showHideSlidingPaneClassName = ui.reportSlidingPane.open
    ? 'modal-report display-block'
    : 'modal-report display-block'

  //Set titleHeader, showTable, toolTipText based on report type
  let titleHeader
  let showTable
  let toolTipText

  const auditType =
    ui.auditReportTypeAndTime.type !== 'All'
      ? ui.auditReportTypeAndTime.type
      : ''

  switch (path) {
    case 'snapshot':
      titleHeader =
        ui.snapshotData.time === '' ? (
          <div />
        ) : (
          `Care Team At ${ui.snapshotData.time} on ${ui.snapshotData.date}`
        )
      showTable = ui.snapshotData.time === '' ? false : true
      toolTipText = `Snapshot Report
          Snapshot of a previous day/night shift that displays patient, room, and assigned caregiver(s).`
      break
    case 'missingassignments':
      titleHeader =
        ui.missingAssignmentData.timeIncrementInHours === '' ? (
          <div />
        ) : (
          `${reportingData.length} Missing Assignments in the last ${ui.missingAssignmentData.timeIncrementInHours} hours` ||
          `${reportingData.length} Missing Assignments from ${ui.missingAssignmentData.startDateTime} to ${ui.missingAssignmentData.endDateTime}`
        )

      showTable =
        ui.missingAssignmentData.timeIncrementInHours !== '' ||
        ui.missingAssignmentData.startDateTime !== ''
      toolTipText = `Missing Assignments Report
        Patients that have not had a bedside nursing team assignment for 10 minutes or more.`
      break
    case 'assignmentaudit':
      if (auditType === '' && ui.auditReportTypeAndTime.time === '') {
        titleHeader = <div />
      } else if (ui.auditReportTypeAndTime.time === '7Days') {
        titleHeader = `All ${auditType} Assignments from ${ui.auditReportTypeAndTime.startDate} ${ui.auditReportTypeAndTime.startTime} to ${ui.auditReportTypeAndTime.endDate} ${ui.auditReportTypeAndTime.endTime}`
      } else {
        titleHeader = `All ${auditType} Assignments In The Last ${ui.auditReportTypeAndTime.time} Hours`
      }
      showTable = !(auditType === '' && ui.auditReportTypeAndTime.time === '')
      toolTipText = `Assignment Audit Report
        List of CTA-created events for troubleshooting assignments in downstream systems.`
      break
    default:
      break
  }

  const sortedUnits = useMemo(
    () =>
      [...units.units].sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1
        }
        if (a.displayName > b.displayName) {
          return 1
        }
        return 0
      }),
    [units],
  )

  // keeps sliding pane open on page refresh
  useEffect(() => {
    if (showHideSlidingPaneClassName) {
      handleSlidingPane()
    }
  }, [window.location.pathname])

  return (
    <Flex direction={'column'}>
      {showPane && (
        <SlidingPane
          slidingPane={ui.reportSlidingPane}
          showHideReportClassName={showHideSlidingPaneClassName}
          slidingPaneOpen={ui.reportSlidingPane.open}
          isVisible={slidingPaneVisible}
          handleSlidingPane={(e) => handleSlidingPane()}
          ui={ui}
          hideSlidingPane={() =>
            dispatch({
              type: 'ui/REPORTS_SLIDING_PANE',
              payload: { open: false, source: '', data: {} },
            })
          }
          units={sortedUnits}
          path={path}
          previousPath={previousPath}
          positions={positions}
          site={selectedSiteId}
          authToken={authToken}
          user34={user34}
          authorizedGlobalAdmin={authorizedGlobalAdmin}
          authorizedLocalAdmin={authorizedLocalAdmin}
        />
      )}
      <SubNavigation
        page={selPage}
        handleSlidingPane={(e) => handleSlidingPane()}
      />
      {ui.flashMessage.messages.length > 0 ? (
        <GenericErrorSnackbar ui={ui} />
      ) : (
        <></>
      )}
      <Flex style={{ width: '100%', minHeight: 'calc(100vh - 124px)' }}>
        <DropShadowDiv>
          <div
            style={{
              paddingTop: '25px',
              flex: 1,
            }}
          >
            {fetchingReport ? (
              <Loading square="100px" />
            ) : (
              <Flex direction="column" style={{ padding: '0 40px' }}>
                <Flex style={{ justifyContent: 'space-between' }}>
                  <Flex>
                    <Flex>
                      <div
                        className="button"
                        style={{
                          backgroundColor: '#00558C',
                          color: 'white',
                          fontFamily: 'HCA-Mark',
                          marginTop: 0,
                          display: 'flex',
                          width: '176px',
                          height: '40px',
                          textAlign: 'center',
                          borderRadius: '5px',
                          boxSizing: 'border-box',
                          cursor: 'pointer',
                          fontWeight: '600',
                          textDecoration: 'none',
                          textTransform: 'capitalize',
                          fontSize: '16px',
                          padding: '10px 15px 5px 15px',
                        }}
                        onClick={(e) => handleSlidingPane()}
                      >
                        <div style={{ height: 'max-content' }}>
                          <NeuIcon large>keyboard_arrow_left</NeuIcon>
                        </div>
                        <span>Modify Report</span>
                      </div>
                      {!ui.reportSlidingPane.open && (
                        <div
                          style={{
                            paddingTop: '7px',
                            cursor: 'pointer',
                            paddingLeft: '15px',
                          }}
                        >
                          <NeuTooltip
                            style={{
                              textAlign: 'center',
                              whiteSpace: 'pre-line',
                            }}
                            tip={toolTipText}
                          >
                            <NeuIcon
                              style={{
                                color: '#898B8E',
                              }}
                            >
                              info
                            </NeuIcon>
                          </NeuTooltip>
                        </div>
                      )}
                    </Flex>
                    {path === 'missingassignments' && reportHasData && (
                      <Flex
                        style={{
                          paddingTop: '10px',
                          cursor: 'pointer',
                          paddingLeft: '15px',
                        }}
                      >
                        <Checkbox
                          id="hide-discharged-patients-checkbox"
                          isSelected={hideDischargedPatients}
                          click={(e) =>
                            setHideDischargedPatients(!hideDischargedPatients)
                          }
                        />{' '}
                        <label style={{ paddingLeft: '10px' }}>
                          Hide discharged patients
                        </label>
                      </Flex>
                    )}
                  </Flex>
                    <Flex>
                    {/* May need to re-add this later if users want to print PDFs */}
                    {/* {path === 'snapshot' &&
                      showTable &&
                      reportingData.length > 0 && (
                        <PDFPrint
                          style={{
                            backgroundColor: 'white',
                          }}
                          kind="tertiary"
                          onClick={() =>
                            pdfReport(
                              formatSnapshot(
                                fetchingReport === false
                                  ? filterByKeyword(
                                      reportingData,
                                      ui.modifyReportKeyword,
                                    )
                                  : [],
                                ui.snapshotData.time,
                                ui.snapshotData.date,
                              ),
                            )
                          }
                        >
                          <Flex alignItems="center" justifyContent="center">
                            <NeuIcon
                              fill={true}
                              color="#00548c"
                              style={{
                                paddingTop: '3px',
                                paddingRight: '5px',
                              }}
                            >
                              print
                            </NeuIcon>
                            PDF
                          </Flex>
                        </PDFPrint>
                      )} */}
                    {showTable && reportingData.length > 0 ? (
                      <CSVLink
                        style={{
                          textDecoration: 'none',
                          borderRadius: '5px',
                        }}
                        data={
                          reportHasData ? report : [generateMockReport(path)]
                        }
                        filename="careteam.csv"
                        headers={headers}
                        target="_blank"
                      >
                        <CSVPrint kind="tertiary">
                          <Flex alignItems="center" justifyContent="center">
                            <NeuIcon
                              fill={true}
                              color="#00548c"
                              style={{
                                paddingTop: '3px',
                                paddingRight: '5px',
                              }}
                            >
                              print
                            </NeuIcon>
                            CSV
                          </Flex>
                        </CSVPrint>
                      </CSVLink>
                    ) : null}
                  </Flex>
                </Flex>

                <Flex direction="column">
                  <div
                    style={{
                      fontSize: '22px',
                      fontFamily: 'HCA-Mark, Arial, sans-serif',
                      fontWeight: '500',
                      marginTop: '10px',
                      marginBottom: '5px',
                    }}
                  >
                    {titleHeader}
                  </div>
                  {path === 'missingassignments' &&
                  ui.missingAssignmentData.timeReportGenerated !== '' ? (
                    <div
                      style={{
                        fontSize: '14px',
                        fontFamily: 'HCA-Mark, Arial, sans-serif',
                        marginTop: '5px',
                        marginBottom: '5px',
                      }}
                    >
                      Report generated at{' '}
                      {ui.missingAssignmentData.timeReportGenerated}
                    </div>
                  ) : (
                    <div />
                  )}
                </Flex>
              </Flex>
            )}

            <div />
            {showTable ? (
              <ReportsTable
                path={path}
                reportingData={
                  fetchingReport === false
                    ? filterByKeyword(reportingData, ui.modifyReportKeyword)
                    : []
                }
                assignmentAuditData={assignmentAuditData}
                alphabetizedPositions={alphabetizedPositions}
                fetchingReports={ui.fetchingReports}
                hideDischargedPatients={hideDischargedPatients}
              />
            ) : (
              <div />
            )}
          </div>
        </DropShadowDiv>
      </Flex>
    </Flex>
  )
}

const mapReduxStateToProps = function (state, props) {
  return {
    user34: state.user.user.hca34,
    user: state.user.user,
    authToken: state.user.token,
    sites: state.sites,
    selectedSiteId: state.sites.selectedSiteId,
    units: state.units,
    ui: state.ui,
    positions: state.groupAndStaffSlotMetaData.userGroups,
    assignmentAudit: state.reports.assignmentAudit,
    snapshot: state.reports.snapshot,
    missingAssignments: state.reports.missingAssignments,
    fetchingReport: state.reports.fetchingReport,
    currentPath: state.user.currentPath,
  }
}

export default connect(mapReduxStateToProps)(Reports)
