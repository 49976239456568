/**
 * External Imports
 * */
import { connect } from 'react-redux'
import { NeuIcon } from '@neutron/react'
/**
 * Styling Imports
 * */
import {
  ModalContainer,
  CloseButton,
  ModalRow,
  Bubble,
  LocationSpan,
} from './assigning.styles'

function AssignmentsModal(props) {
  return (
    <div
      className={
        props.showAssignmentsModal
          ? 'modal display-block'
          : 'modal display-none'
      }
    >
      <div
        className="modal-main"
        style={{
          width: '600px',
          height: '645px',
          borderRadius: '5px',
          display: 'block',
        }}
      >
        <ModalContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              alignItems: 'center',
            }}
          >
            <div>
              <h6 style={{ fontWeight: 'bold' }}>
                All Assignments For {props.filteredBubbleMetadata.firstName}{' '}
                {props.filteredBubbleMetadata.lastName}
              </h6>
            </div>
            <div>
              <CloseButton
                onClick={() => {
                  props.dispatch({
                    type: 'ui/HIDE_ASSIGNMENTS_MODAL',
                  })
                }}
              >
                <NeuIcon color="gray-60">cancel</NeuIcon>
              </CloseButton>
            </div>
          </div>
          <div
            style={{
              height: '593px',
              overflowY: 'auto',
            }}
          >
            {props.filteredBubbleMetadata.assignments.map((item, index) => {
              return (
                <ModalRow key={item.location + index}>
                  <Bubble>
                    <span>{item.role}</span>
                  </Bubble>
                  <LocationSpan style={{ fontWeight: 'bold' }}>
                    {item.location}
                  </LocationSpan>
                  <span
                    style={{
                      verticalAlign: 'middle',
                      marginLeft: '10px',
                    }}
                  >
                    {item.patientLastName !== ''
                      ? `${item.patientLastName}, `
                      : ''}
                  </span>
                  <span
                    style={{
                      verticalAlign: 'middle',
                      fontSize: '.9em',
                    }}
                  >
                    {item.patientFirstName}
                  </span>
                </ModalRow>
              )
            })}
          </div>
        </ModalContainer>
      </div>
    </div>
  )
}

const mapStateToProps = function (state, props) {
  return {
    showAssignmentsModal: state.ui.showAssignmentsModal,
    filteredBubbleMetadata: state.staffPool.filteredBubbleMetadata,
  }
}

export default connect(mapStateToProps)(AssignmentsModal)
