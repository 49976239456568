/**
 * External Imports
 * */
import { useEffect } from 'react'
/**
 * Internal Imports
 * */
import Loading from '../reusableComponents/neutronComponents/Spinner'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'

export const validRoutes = [
  '/',
  '/locationassignments',
  '/patientassignments',
  '/reports/snapshot',
  '/reports/missingassignments',
  '/reports/assignmentaudit',
  '/manage/users',
  '/manage/locations',
  '/manage/patients',
]

const storeLastValidPath = (path) => {
  if (validRoutes.includes(path)) {
    localStorage.setItem('ctaLastPath', path)
  }
}

const Redirect = () => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const ctaLastPath =
      localStorage.getItem('ctaLastPath') || 'locationassignments'
    // notifier only works if url is a # instead of ?
    // todo: add a real redirect uri
    // https://tools.ietf.org/html/rfc6749#section-3.1.2.5

    if (location.pathname !== '/redirect') {
      if (validRoutes.includes(location.pathname)) {
        storeLastValidPath(location.pathname)
        history.replace(location.pathname)
      } else {
        history.replace(ctaLastPath)
      }
    }

    const isQuery = location.search.includes('?')

    if (isQuery) {
      const newParams = location.search.replace('?', '#')
      history.push(newParams)
    }
  }, [history, location])

  return (
    <div
      style={{
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading square="100px" />
    </div>
  )
}

export default Redirect
