/**
 * External Imports
 * */
import { useState, useEffect, useCallback } from 'react'
import { NeuCard, NeuTable } from '@neutron/react'
import _ from 'lodash'
/**
 * Internal Imports
 * */
import ReportsList from './reportsList'

import { naturalSort } from '../../utils/naturalSort'

const getHeaders = (str) => {
  const columnHeaders = {
    missingassignments: [
      { label: 'UNIT', key: 'unit', multiplier: 1, sortable: false },
      { label: 'BED', key: 'bed', multiplier: 1, sortable: false },
      { label: 'PATIENT', key: 'patientName', multiplier: 1, sortable: false },
      { label: 'MISSING', key: 'missing', multiplier: 1, sortable: false },
      {
        label: 'MISSING SINCE',
        key: 'missingSince',
        multiplier: 1,
        sortable: true,
      },
      {
        label: 'ASSIGNMENT FILLED',
        key: 'assignmentFilled',
        multiplier: 1,
        sortable: false,
      },
    ],
    snapshot: [
      { label: 'UNIT', key: 'unit', multiplier: 1, sortable: false },
      { label: 'BED', key: 'bed', multiplier: 1, sortable: false },
      { label: 'PATIENT', key: 'patientName', multiplier: 1, sortable: false },
      { label: 'CARE TEAM', key: 'careTeam', multiplier: 0, sortable: false },
      {
        label: 'TEAM LAST UPDATED',
        key: 'teamLastUpdated',
        multiplier: 1,
        sortable: false,
      },
    ],
    assignmentaudit: [
      { label: 'TIME', key: 'time', multiplier: 1, sortable: true },
      { label: 'UNIT', key: 'unit', multiplier: 1, sortable: false },
      { label: 'BED', key: 'bed', multiplier: 1, sortable: false },
      { label: 'PATIENT', key: 'patientName', multiplier: 1, sortable: false },
      { label: 'ACTION', key: 'action', multiplier: 1, sortable: false },
      { label: 'POSITION', key: 'position', multiplier: 1, sortable: false },
      {
        label: 'STAFF MEMBER',
        key: 'staffMember',
        multiplier: 1,
        sortable: false,
      },
      { label: 'MADE BY', key: 'madeBy', multiplier: 1, sortable: false },
    ],
  }
  return columnHeaders[str]
}

const ReportsTable = ({ path, reportingData, snapshotPosition }) => {
  const [height, setHeight] = useState(
    path === 'missingassignments'
      ? window.innerHeight - 332
      : window.innerHeight - 302,
  )
  const [sortDirection, setSortDirection] = useState(true)
  const [selected, setSelected] = useState(
    path === 'missingassignments' ? 'missingSince' : '',
  )

  const columnHeaders = getHeaders(path)

  // useCallback: Memoizes the tableHeight function to avoid recreating it on every render.
  const tableHeight = useCallback(
    (e) => {
      setHeight(path === 'missingassignments' ? e - 332 : e - 302)
    },
    [path],
  )

  useEffect(() => {
    const handleResize = _.debounce((e) => {
      const target = e.currentTarget
        ? e.currentTarget.innerHeight
        : e.srcElement.innerHeight
      tableHeight(target)
    }, 750)

    window.addEventListener('resize', handleResize)
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [tableHeight])

  const headerColumnClickHandler = (index, header) => {
    setSortDirection(!sortDirection)
    setSelected(header.key)
  }
  const handleSort = ({ key, direction }) => {
    if (!key) return reportingData
    const hash = []
    const list = reportingData
    list.forEach((item) => {
      if (key === 'clinicalRole') {
        hash.push(item[key].displayName.toUpperCase())
      } else {
        hash.push(item[key].toUpperCase())
      }
    })

    const orderedHash = hash.sort(naturalSort)
    const orderedList = []
    for (let i = 0; i < orderedHash.length; i++) {
      for (let j = 0; j < list.length; j++) {
        if (key === 'clinicalRole') {
          if (orderedHash[i] === list[j][key].displayName.toUpperCase()) {
            orderedList.push(list[j])
          }
        } else if (orderedHash[i] === list[j][key].toUpperCase()) {
          orderedList.push(list[j])
        }
      }
    }
    const result = _.uniqBy(orderedList, 'id')
    return direction ? result : result.reverse()
  }

  const sortedList = handleSort({
    key: selected,
    direction: sortDirection,
  })

  return (
    <NeuCard
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(95vh - 36px)',
        overflowX: 'hidden',
      }}
    >
      <NeuTable kind="default" sticky="header" size="default">
        <NeuTable unit="header">
          <NeuTable unit="row">
            {columnHeaders.map((header, index) => (
              <NeuTable
                unit="column"
                key={header.key}
                header={true}
                sort={
                  path === 'missingassignments' && index === 4
                    ? true
                    : path === 'assignmentaudit' && index === 0
                      ? true
                      : false
                }
                direction={
                  index === 4
                    ? selected === header.key && !sortDirection
                      ? 'desc'
                      : 'asc'
                    : index === 0
                      ? selected === header.key && !sortDirection
                        ? 'desc'
                        : 'asc'
                      : ''
                }
                onNeuSort={() => {
                  if (header.sortable) {
                    headerColumnClickHandler(index, header)
                  }
                }}
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: '12px',
                  cursor: header.sortable ? 'pointer' : '',
                  flex: header.key === 'careTeam' ? '0 0 720px' : undefined,
                }}
              >
                <div
                  style={{
                    color: selected === header.key ? '#03173e' : '#58595B',
                    fontSize: '14px',
                    fontWeight: '500',
                    paddingTop: '5px',
                    marginRight: '10px',
                  }}
                >
                  {header.label}
                </div>
              </NeuTable>
            ))}
          </NeuTable>
        </NeuTable>
        <NeuTable unit="content">
          {reportingData.length > 0 ? (
            <div>
              <ReportsList
                reportingData={sortedList}
                path={path}
                columns={columnHeaders}
                snapshotPosition={snapshotPosition}
              />
            </div>
          ) : (
            <div
              style={{
                marginLeft: '40px',
                fontSize: '1.5em',
                paddingTop: '10px',
              }}
            >
              There are no entries matching your search criteria.
            </div>
          )}
        </NeuTable>
      </NeuTable>
    </NeuCard>
  )
}

export default ReportsTable
