/**
 * External Imports
 * */
import { useEffect } from 'react'
import { connect } from 'react-redux'
/**
 * Internal Imports
 * */
import SubNavigation from './headers/subNav/SubNav'
import TableContainer from './table/container'
import StaffContainer from './staff/staffContainer'
import SidePanel from './staff/SidePanel'
import Flex from './reusableComponents/styleLibrary'
// To Do: do we need the below misspelled path
import NursingAssignmentWarning from './reusableComponents/nursingAssigmentWarning/NursingAssignmentWarning'
import Loading from './reusableComponents/neutronComponents/Spinner'
import AssignmentModal from './reusableComponents/neutronComponents/assigning/AssignmentModal'

import { userSettings } from '../redux/actions/user'
import locationAssignmentSelector from '../redux/selectors/locationAssignmentSelector'
/**
 * Styling Imports
 * */
import styled from 'styled-components'

const Main = ({
  disabledNav,
  idsForAssignedStaff,
  isDraftAssignment,
  sites,
  fetchingLocations,
  locations,
  preferredStaff,
  selPage,
  selectedStaffMember,
  selectedStaffSlots,
  selectedUserGroupId,
  staffPool,
  selectedStaffSlotsState,
  selectedUserGroupName,
  showHideDisabledModal,
  staffSearch,
  staffSlots,
  staffSlotsPerRoleGroup,
  stagedStaffSlotErrors,
  units,
  user,
  userGroups,
  dispatch,
}) => {
  useEffect(() => {
    if (!sites.selectedSiteId && units) {
      dispatch(
        userSettings({
          authToken: user.token,
          siteId: sites.selectedSiteId,
        }),
      )
    }
  }, [selectedStaffSlots, sites.selectedSiteId, units, user.token, dispatch])

  const printAssignmentType = (type) => {
    return (
      <>
        <TableContainer
          staffSlots={staffSlots}
          selectedUserGroupName={selectedUserGroupName}
          staffSlotsPerRoleGroup={staffSlotsPerRoleGroup}
          page={selPage}
          stagedStaffSlotErrors={stagedStaffSlotErrors}
          selectedUnitIds={units.selectedUnitIds}
        />
        {type === 'patients' && selectedUserGroupName === 'Nursing' && (
          <NursingAssignmentWarning />
        )}
      </>
    )
  }

  const editedPatientList =
    locations.locations &&
    locations.locations
      .filter((assignment) => {
        if (assignment.patient) {
          return true
        }
        return false
      })
      .map((assignment) => {
        const patient = Object.assign({}, assignment.patient)
        patient.dateOfBirth = patient.dateOfBirth.substring(0, 10)
        patient.location = assignment.location
        return patient
      })

  const editedPatientState = Object.assign({}, locations)

  editedPatientState.locations = editedPatientList

  const editedLocations =
    locations.locations &&
    locations.locations.map((entry) => {
      const patient = entry.patient && Object.assign({}, entry.patient)
      const location = entry.location && Object.assign({}, entry.location)

      if (patient) {
        patient.dateOfBirth = patient.dateOfBirth.substring(0, 10)
      }

      // attach patient to location
      location.patient = patient

      return location
    })

  const editedLocationState = Object.assign({}, locations)

  editedLocationState.locations = editedLocations

  const selectedPage = selPage

  if (userGroups.fetching || user.fetchingUserSettings) {
    return (
      <StyledDiv>
        <Loading square="100px" />
      </StyledDiv>
    )
  }

  return (
    <Flex
      direction="column"
      style={{ height: '100%', minWidth: '1280px !important', width: '100%' }}
    >
      {user.userSettings && (
        <SubNavigation
          page={selPage}
          showHideDisabledModal={showHideDisabledModal}
          disabledNav={disabledNav}
          staffSlots={staffSlots}
          patientAssignments={editedPatientList}
          locationAssignments={editedLocations}
          selectedStaffSlots={selectedStaffSlotsState}
        />
      )}

      <Container>
        {staffPool.length > 0 && (
          <SidePanel>
            <StaffContainer
              selectedUserGroupName={selectedUserGroupName}
              staffPool={staffPool}
              selectedStaffMember={selectedStaffMember}
              selectedUserGroupId={selectedUserGroupId}
              userGroups={userGroups}
              preferredStaff={preferredStaff}
              idsForAssignedStaff={idsForAssignedStaff}
              fetchingLocations={fetchingLocations}
              staffSlots={staffSlots}
              isDraftAssignment={isDraftAssignment}
              staffSearch={staffSearch}
              dispatch={dispatch}
            />
          </SidePanel>
        )}
        <AssetMenuContainer>
          {printAssignmentType(selectedPage)}
        </AssetMenuContainer>
      </Container>
      <AssignmentModal />
    </Flex>
  )
}

const AssetMenuContainer = styled.div`
  display: contents;
  flex-direction: column;
`

const Container = styled.div`
  min-height: 1px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
// To Do: do we need the below misspelled styling
const StyledDiv = styled.div`
  height: 300px;
  display: flex;
  justifycontent: center;
  alignitems: center;
`

const mapReduxStateToProps = function (state, props) {
  return {
    preferredStaff: state.facilitySchedulerUsers.preferredStaff,
    userGroups: state.userGroups.userGroups,
    ...locationAssignmentSelector(state, props),
    sites: state.sites,
    user: state.user,
    staffPool: state.staffPool.staffPool,
    facilitySchedulerUsers: state.facilitySchedulerUsers,
    authToken: state.user.token,
    selectedSiteId: state.sites.selectedSiteId,
    selectedUserGroupId: state.userGroups.selectedUserGroupId,
    selectedStaffMember: state.staffPool.selectedStaffMember,
    idsForAssignedStaff: state.locations.idsForAssignedStaff,
    fetchingLocations: state.locations.fetching,
    showAssignmentsModal: state.ui.showAssignmentsModal,
    isDraftAssignment: state.ui.isDraftAssignment,
    staffSearch: state.ui.staffSearch,
  }
}

export default connect(mapReduxStateToProps)(Main)
