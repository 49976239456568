import styled from 'styled-components'

export const Snackbar = styled.div`
  background-color: #bfe0f1 !important;
  width: 840px !important;
  flex: none !important;
  justify-content: center !important;
  position: absolute;
  height 50px;
  left: 54%;
  top: 83%;
  transform: translate(-50%, -50%);
  border: none !important; // Remove border
  border-radius: 5px; // Add border radius of 5px
  z-index: 2;
  margin-left: 5px;
  text-align: center;
  font-weight: bold;
`
