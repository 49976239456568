import styled from 'styled-components'

// SidePanel
export const Container = styled.div`
  margin-top: 22.5px;
  width: 280px !important;
  padding: 10px 15px;
  display: flex;
  flex: 0 0 280px;
  flex-direction: column;
  .Search_Border-Responsive {
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
  }
  overflow-y: 'scroll';
`

//staffContainer

export const SortContainer = styled.div`
  width: 200px;
  height: 100px;
  top: 50px;
  background-color: white;
  position: absolute;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 99;
  box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.1);
`

export const SortOptions = styled.div`
  postition: absolute;
  width: 200px;
  z-index: 4;
  height: 100px;
  background-color: white;
  z-index: 9099;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
// staffTable

export const RowContainer = styled.div`
  border-radius: 5px;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
`

export const Bubble = styled.div`
  min-width: 25px;
  width: max-content;
  height: 25px;
  border-radius: 15px;
  line-height: 25px;
  background: ${({ type }) => (type === 'primary' ? '#03173e' : '#9bb8d3')};
  color: ${({ type }) => (type === 'primary' ? 'white' : '#03173e')};
  text-align: center;
  margin-right: 5px;
  > span {
    padding: 0 5px;
  }
`

export const RowCard = styled.div.attrs({
  className: 'cursor',
})`
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  border-radius: 5px;
  background-color: white;
  .selectedCard {
    background-color: lightblue;
  }
  :hover {
    border-style: solid;
    border-width: 1px;
    border-color: #03173e;
  }
`

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  cursor: default;
  height: 100%;
  align-items: center;
`

export const TooltipText = styled.div`
  width: max-content;
  height: auto;
  visibility: hidden;
  // font-family: 'HCA-Mark-Medium', 'Arial', sans-serif;
  font-size: 0.75em;
  white-space: normal;
  background-color: #1f2532;
  color: #ffffff;
  text-align: left;
  border-radius: 5px;
  padding: 10px !important;
  position: absolute;
  z-index: 1;
  top: 45px;
  right: 85%;
  opacity: 0;
  line-height: 1.75em;
  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`
export const StaffList = styled.div`
  padding: 8px 0px 0px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 154px;
`
export const StaffRole = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
`
