/**
 * External Imports
 */
import styled from 'styled-components'
import { NeuButton } from '@neutron/react'

export const CSVPrint = styled(NeuButton)`
  font-size: 12.6px !important;
  font-weight: bolder !important;
  margin-top: 0px !important;
  border-radius: 5px !important;
  border: none !important;
  width: 78px !important;
  display: flex !important;
  color: #00548c !important;
  margin-top: 0 !important;
  margin-right: 2% !important;
`

export const PDFPrint = styled(NeuButton)`
  font-size: 12.6px !important;
  font-weight: bolder !important;
  margin-top: 0px !important;
  border-radius: 5px !important;
  border: none !important;
  width: 78px !important;
  display: flex !important;
  color: #00548c !important;
  margin-top: 0 !important;
  margin-right: 2% !important;
`
