/**
 * External Imports
 * */
import { useState } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { SiteAccessDiv } from '../../table/table.styles'

const AssignedLocationDisplay = ({ assignedLocations }) => {
  // The following arrayOfPossibleValues and currentDisplayAmount generates
  // a list of show more amounts as defined by the UX/UI requirements.
  // Toggle the value in Array(20) to higher (multiple of 10) if number of
  // sites exceeds maximum to display (current 200)
  const arrayOfPossibleValues = [...Array(20).values()]
  const currentDisplayAmount = [
    3,
    ...arrayOfPossibleValues.map((val, index) => {
      return (index + 1) * 10
    }),
  ]

  // [3, 10, 20, 30, ... 200]
  const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0)

  return (
    <div>
      {_.sortBy(assignedLocations, 'displayName').map((location, alIndex) => {
        if (alIndex < currentDisplayAmount[currentDisplayIndex]) {
          return (
            <div key={uuid()}>
              <SiteAccessDiv key={location.displayName}>
                {location.displayName}
              </SiteAccessDiv>
            </div>
          )
        } else if (alIndex < 3) {
          return (
            <div key={uuid()}>
              <SiteAccessDiv key={location.displayName}>
                {location.displayName}
              </SiteAccessDiv>
            </div>
          )
        } else {
          return
        }
      })}
      {assignedLocations.length - currentDisplayAmount[currentDisplayIndex] >
        0 && (
        <div
          style={{
            color: '#0085ca',
            cursor: 'pointer',
          }}
          onClick={() => {
            setCurrentDisplayIndex(currentDisplayIndex + 1)
          }}
        >
          <i className="material-icons">arrow_drop_down</i>
          <span>
            {assignedLocations.length -
              currentDisplayAmount[currentDisplayIndex]}{' '}
            More (Show More)
          </span>
        </div>
      )}
      {currentDisplayAmount[currentDisplayIndex] >= 10 && (
        <div
          style={{
            color: '#0085ca',
            cursor: 'pointer',
          }}
          onClick={() => {
            setCurrentDisplayIndex(0)
          }}
        >
          <i className="material-icons">arrow_drop_up</i>
          <span>Less (Show Less)</span>
        </div>
      )}
    </div>
  )
}

export default AssignedLocationDisplay
