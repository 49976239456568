import styled from 'styled-components'
import { NeuButton } from '@neutron/react'

export const SlidingPaneDiv = styled.div`
  background-color: white;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: -420px;
  width: 420px;
  z-index: 2;
`
export const CancelButton = styled(NeuButton)`
  width: 145px !important;
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none !important;
  color: #005589 !important;

  &:hover {
    background-color: #cdd1d9 !important;
  }
`
export const RunButton = styled(NeuButton)`
  width: 145px !important;
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none !important;
  color: white !important;
  background-color: #005589 !important;
`
