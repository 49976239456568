import styled from 'styled-components'
import { NeuButton } from '@neutron/react'

export const UnpublishedModal = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: solid 1px;
  left: 30%;
  top: 30%;
  height: 200px;
  border-radius: 5px;
  width: 420px;
  z-index: 2;
`
export const DiscardButton = styled(NeuButton)`
  font-size: 16px !important;
  background-color: transparent !important;
  padding: 0 15px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  border-radius: 5px !important;
  height: 40px !important;
  box-sizing: border-box !important;
  width: 95px !important;
  max-width: 300px !important;
  border: none !important;
`
export const AssignButton = styled(NeuButton)`
  font-size: 16px !important;
  padding: 0 15px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  border-radius: 5px !important;
  height: 40px !important;
  box-sizing: border-box !important;
  width: 95px !important;
  max-width: 300px !important;
`
export const ButtonsDiv = styled.div`
  display: flex !important;
  justify-content: flex-end;
  margin-top: 5px;
  padding-right: 10px;
`
export const ConfirmText = styled.p`
  font-size: 16px;
  line-height: 25px !important;
  letter-spacing: 0.2p;
  padding-right: 2px !important;
  padding-bottom: 20px !important;
`
