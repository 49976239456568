/**
 * Style Imports
 * */
import { BasicChip, RoleTypeBubble, AssignmentText } from './chip.styles'

function checkChipType({
  isFreshAssignment,
  userCurrentlyAssigned,
  assignmentType,
  assigningUser,
  selected,
  isDisabled,
  context,
}) {
  const removal = assignmentType === 'remove'
  const assigning = assignmentType === 'assign'

  const baseStyle = {
    basicChip: {
      className: {},
      styles: {
        backgroundColor: 'white',
        border: 'solid 0.5px #898b8e',
      },
    },
    assignmentText: {
      styles: { width: '100%', textAlign: 'center', color: '#1F2532' },
    },
  }
  const disabledStyle = {
    basicChip: {
      className: {},
      styles: {
        backgroundColor: 'white',
      },
    },
  }
  if (
    (userCurrentlyAssigned && selected && !removal && !assigning) ||
    (userCurrentlyAssigned && selected && removal) ||
    (userCurrentlyAssigned && selected)
  ) {
    // blue
    return Object.assign({}, baseStyle, {
      basicChip: {
        styles: {
          border: 'solid 1.5px #005589',
          backgroundColor: '#bfe0f2',
          padding: '1.5px',
        },
      },
      roleTypeBubble: {
        styles: { backgroundColor: '#005589', color: 'white' },
      },
      assignmentText: { styles: { color: '#005589', fontWeight: 'heavy' } },
    })
  } else if (isDisabled && userCurrentlyAssigned) {
    return Object.assign({}, disabledStyle, {
      basicChip: {
        styles: {
          justifyContent: 'center',
          backgroundColor: 'white',
          border: 'none',
        },
      },
      assignmentText: { styles: { color: 'dark grey' } },
    })
  } else if (isDisabled && !userCurrentlyAssigned) {
    return Object.assign({}, disabledStyle, {
      basicChip: {
        styles: {
          justifyContent: 'center',
          backgroundColor: 'white',
          border: 'none',
        },
      },
      assignmentText: { styles: { color: 'grey' } },
    })
  } else if (isFreshAssignment && assigning && !selected) {
    return Object.assign({}, baseStyle, {
      basicChip: { styles: { backgroundColor: '#e8f5d7', border: 'none' } },
      roleTypeBubble: {
        styles: { backgroundColor: '#E8F5D7', border: 'none' },
      },
      icon: {
        className: 'material-icons',
        styles: {
          color: '#599662',
        },
        type: 'add',
      },
      assignmentText: { styles: { color: '#599662', border: 'none' } },
    })
  } else if (userCurrentlyAssigned && assigning && !selected) {
    // swap
    return Object.assign({}, baseStyle, {
      basicChip: {
        styles: {
          backgroundColor: '#fff1d0',
          border: 'none',
          paddingRight: '10px',
        },
      },
      roleTypeBubble: {
        styles: { backgroundColor: '#FFF1D0', border: 'none' },
      },
      icon: {
        className: 'material-icons',
        styles: {
          color: ' #956C24',
          paddingBottom: '3px',
          paddingRight: '0px !important',
        },
        type: 'loop',
      },
      assignmentText: { styles: { color: ' #956C24' } },
    })
  } else if (
    !isFreshAssignment &&
    userCurrentlyAssigned &&
    assigningUser &&
    assigning &&
    selected
  ) {
    // dark swap
    return Object.assign({}, baseStyle, {
      basicChip: {
        styles: { border: 'solid 1.5px #956C24', backgroundColor: '#fff1d0' },
      },
      roleTypeBubble: {
        styles: { backgroundColor: ' #956C24', height: '23px' },
      },
      icon: {
        className: 'material-icons',
        styles: {
          color: ' #FFF1D0',
          border: 'none',
          paddingBottom: '3px',
          fontSize: '20px',
        },
        type: 'loop',
      },
      assignmentText: { styles: { color: ' #956C24' } },
    })
  } else if (isFreshAssignment && assigningUser && selected && assigning) {
    // dark green
    return Object.assign({}, baseStyle, {
      basicChip: {
        styles: { backgroundColor: '#e8f5d7', border: 'none' },
      },
      roleTypeBubble: {
        styles: {
          backgroundColor: ' #599662 ',
          height: '23px',
          border: 'none',
        },
      },
      icon: {
        className: 'material-icons',
        styles: {
          color: '#e8f5d7',
          paddingBottom: '3px',
          fontSize: '20px',
        },
        type: 'add',
      },
      assignmentText: { styles: { color: '#599662', border: 'none' } },
    })
  } else if (userCurrentlyAssigned && removal && !assigning) {
    // red
    return Object.assign({}, baseStyle, {
      basicChip: { styles: { backgroundColor: '#f9dbde', border: 'none' } },
      roleTypeBubble: {
        styles: { backgroundColor: '#F9DBDE', border: 'none' },
      },
      icon: {
        className: 'material-icons',
        styles: {
          color: '#CE2130',
        },
        type: 'remove',
      },
      assignmentText: { styles: { color: '#54575A' } },
    })
  } else if (
    (userCurrentlyAssigned && !assigning) ||
    (userCurrentlyAssigned && !removal) ||
    context === 'slidingPane'
  ) {
    // I don't know why context === 'slidingPane' works, but if it isn't here, it breaks. Sorry.
    return Object.assign({}, baseStyle, {
      basicChip: {
        styles: { backgroundColor: '#bcbcbc', border: 'none' },
      },
      roleTypeBubble: {
        styles: { backgroundColor: '#54575A', color: 'white' },
      },
      assignmentText: { styles: { color: '#54575A' } },
    })
  } else if (!userCurrentlyAssigned && selected && !removal && !assigning) {
    return Object.assign({
      basicChip: {
        className: 'cursor',
        styles: {
          backgroundColor: 'white',
          border: 'solid 2px #005589',
        },
      },
      assignmentText: {
        className: 'neu-text-blue-60 neu-text-bold',
        styles: {
          width: '100%',
          textAlign: 'center',
        },
      },
    })
  } else if (
    (!userCurrentlyAssigned && !assigning) ||
    (!userCurrentlyAssigned && removal)
  ) {
    return baseStyle
  }
}
const Chip = (props) => {
  const {
    isFreshAssignment,
    roleText,
    assignmentText,
    click,
    staged,
    selected,
    action,
    assignedUser,
    isDisabled,
    isClickDisabled,
    context = '',
  } = props

  const type = checkChipType({
    isFreshAssignment,
    userCurrentlyAssigned: assignedUser && assignedUser.length > 0,
    assigningUser: staged,
    assignmentType: action,
    selected,
    isDisabled,
    context,
  })
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    click()
  }

  return (
    <BasicChip
      kind="undefined"
      className={
        !isDisabled || isClickDisabled
          ? 'cursor'
          : '' + type.basicChip && type.basicChip.className
      }
      onClick={handleClick}
      style={{
        ...(type.basicChip && type.basicChip.styles),
        pointerEvents: isClickDisabled ? 'none' : 'false',
        alignItems: 'center',
      }}
    >
      <div>
        {(assignedUser || staged) && !isDisabled && (
          <RoleTypeBubble
            hasIcon={type.icon}
            style={type.roleTypeBubble && type.roleTypeBubble.styles}
          >
            {type.icon && (
              <i className={type.icon.className || ''} style={type.icon.styles}>
                {type.icon.type}
              </i>
            )}
            {!type.icon && assignedUser && (
              <small style={type.roleTypeBubble && type.roleTypeBubble.styles}>
                <div>{roleText}</div>
              </small>
            )}
          </RoleTypeBubble>
        )}
      </div>
      <AssignmentText
        className={type.assignmentText && type.assignmentText.className}
        style={type.assignmentText && type.assignmentText.styles}
      >
        {assignmentText}
      </AssignmentText>
    </BasicChip>
  )
}

const Bubble = ({ text }) => {
  return (
    <RoleTypeBubble
      style={{
        backgroundColor: text === '___' ? '#fff' : '#7A7B7E',
        margin: '-7.5px 0 0 0px',
        width: 'max-content',
        minWidth: '20px',
        marginLeft: '5px',
        marginRight: '5px',
      }}
    >
      <small
        style={{
          color: 'white',
          paddingTop: '3px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </small>
    </RoleTypeBubble>
  )
}

export { Chip, Bubble }
