import _ from 'lodash'

export const filterLocations = (searchText, locations) => {
  if (!searchText) return locations
  if (typeof searchText !== 'string') return locations

  const locationSearch = locations.filter((location) => {
    const singleLocation = location.location ? location.location : location
    const displayName =
      singleLocation.displayName && singleLocation.displayName.toLowerCase()
    const sanitizedSearch = searchText.toLowerCase().trim()

    return displayName && displayName.includes(sanitizedSearch)
  })

  const firstNameSearch = locations.filter((location) => {
    const firstName =
      location.patient &&
      location.patient.firstName &&
      location.patient.firstName.toLowerCase()
    const sanitizedSearch = searchText.toLowerCase().trim()

    return firstName && firstName.includes(sanitizedSearch)
  })

  const lastNameSearch = locations.filter((location) => {
    const lastName =
      location.patient &&
      location.patient.lastName &&
      location.patient.lastName.toLowerCase()
    const sanitizedSearch = searchText.toLowerCase().trim()

    return lastName && lastName.includes(sanitizedSearch)
  })

  const fullNameSearch = (searchText) => {
    const sanitizedSearchText = searchText
      .toLowerCase()
      .replace(/,\s*/g, ' ')
      .replace(/\s+/g, ' ')
      .trim()

    return locations.filter((location) => {
      if (!location.patient) return false

      const firstName = location.patient?.firstName?.toLowerCase().trim() || ''
      const lastName = location.patient?.lastName?.toLowerCase().trim() || ''

      const fullName = `${firstName} ${lastName}`.replace(/\s+/g, ' ').trim()
      const reverseFullName = `${lastName} ${firstName}`
        .replace(/\s+/g, ' ')
        .trim()

      return (
        fullName.includes(sanitizedSearchText) ||
        reverseFullName.includes(sanitizedSearchText)
      )
    })
  }

  const fullNameSearchResults = fullNameSearch(searchText)

  const results = _.uniq([
    ...locationSearch,
    ...firstNameSearch,
    ...lastNameSearch,
    ...fullNameSearchResults,
  ])
  return results
}
