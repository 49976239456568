/**
 * External Imports
 * */
import { useState, useEffect, useCallback, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { NeuCard, NeuCheckbox, NeuTable } from '@neutron/react'
import moment from 'moment'
import _ from 'lodash'
/**
 * Internal Imports
 * */
import SmallLoading from '../reusableComponents/neutronComponents/SmallLoading'
import DataList from './dataList'
import Loading from '../reusableComponents/neutronComponents/Spinner'
import Checkbox from '../reusableComponents/neutronComponents/checkbox/checkbox'

import PatientSlidingPane from '../reusableComponents/PatientSlidingPane'

import { getAllUsers } from '../../redux/actions/user'
import { setSelectedStaffSlot } from '../../redux/actions/selectedStaffSlots'
import { fetchLockedRoles } from '../../redux/actions/groupAndStaffSlotMetaData'

import { flattenList } from '../../utils/flattenList'
import { packageAssignment } from '../../utils/packageAssignment'
import { naturalSort, formatSort } from '../../utils/naturalSort'
/**
 * Styling Imports
 * */
import * as styles from './table.styles'

const getHeaders = (str, props) => {
  const columnHeaders = {
    locationassignments: [
      { label: 'BEDS', key: 'location', multiplier: 1 },
      { label: '', key: 'patient', multiplier: 1.5 },
      props.selectedUserGroupName === 'Nursing'
        ? { label: '', key: '', multiplier: 1 }
        : { label: 'ASSIGNED TO PATIENT', key: '', multiplier: 1 },
      { label: '', key: '', multiplier: 0.5 },
    ],
    patientassignments: [
      { label: 'PATIENT', key: 'patient', multiplier: 2 },
      { label: 'BED', key: 'location', multiplier: 1.5 },
      { label: 'ASSIGNED TO BED', key: '', multiplier: 1 },
      { label: '', key: '', multiplier: 0.5 },
    ],
    users: [
      { label: 'NAME', key: 'lastName', multiplier: 3 },
      { label: 'ROLE', key: 'clinicalRole', multiplier: 2 },
      { label: 'SITE ACCESS', key: 'assignedLocations', multiplier: 2 },
      { label: '', key: '', multiplier: 0.5 },
    ],
    patients: [
      { label: 'BED', key: 'bed', multiplier: 0.5 },
      { label: 'PATIENT', key: 'name', multiplier: 3 },
      { label: '', key: '', multiplier: 0.5 },
    ],
    sites: [
      { label: 'SITES', key: 'displayName', multiplier: 1 },
      { label: '', key: '', multiplier: 1 },
      { label: '', key: '', multiplier: 1 },
      { label: '', key: '', multiplier: 1 },
      { label: '', key: '', multiplier: 1 },
    ],
    units: [
      { label: 'UNITS', key: 'displayName', multiplier: 1 },
      { label: 'STATUS', key: 'status', multiplier: 1 },
      { label: '', key: '', multiplier: 2 },
      { label: '', key: '', multiplier: 1 },
    ],
    rooms: [
      { label: 'ROOMS', key: 'displayName', multiplier: 1 },
      { label: 'STATUS', key: 'status', multiplier: 1 },
      { label: '', key: '', multiplier: 3 },
    ],
    beds: [
      { label: 'BEDS', key: 'displayName', multiplier: 1 },
      { label: 'STATUS', key: 'status', multiplier: 3 },
    ],
    roles: [{ label: 'ROLE', key: 'lastName', multiplier: 1 }],
    departments: [
      { label: 'DEPARTMENT', key: 'lastName', multiplier: 1 },
      { label: 'POSITIONS', key: 'lastName', multiplier: 3 },
    ],
  }

  if (
    str === 'locationassignments' ||
    (str === 'patientassignments' && props.selectedUserGroupName !== 'Nursing')
  ) {
    const chipHeaders = props.staffSlots.map((slot) => ({
      label: slot.abbreviation,
      key: slot.systemName,
      multiplier: 1,
      id: 'chipHeader',
    }))
    for (let i = 0; i < chipHeaders.length; i++) {
      const itemIndex = 2 + i
      columnHeaders[str].splice(itemIndex, 0, chipHeaders[i])
    }
  }
  return columnHeaders[str]
}

const Table = (props) => {
  const [height, setHeight] = useState(
    props.path === 'users'
      ? window.innerHeight - 262
      : window.innerHeight - 297,
  )

  const [currentlySelected, setCurrentlySelected] = useState([])
  const [currentlyDisabledRoles, setCurrentlyDisabledRoles] = useState([])
  const [checkboxIsLocked, setCheckboxIsLocked] = useState(false)

  // Assignment Sliding Pane: Slide In/Out
  const assignmentSlidingPaneOpen = useSelector(
    (state) => state.ui.assignmentSlidingPane.open,
  )
  const [assignmentPaneState, setAssignmentPaneState] = useState(
    assignmentSlidingPaneOpen,
  )
  useEffect(() => {
    if (assignmentSlidingPaneOpen) {
      setAssignmentPaneState(true)
    } else {
      const timer = setTimeout(() => {
        setAssignmentPaneState(false)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [assignmentSlidingPaneOpen])

  const showPane = assignmentPaneState

  let units

  let pageHeaders
  if (props.path === 'locations') {
    pageHeaders = props.dataType.toLowerCase()
  } else {
    pageHeaders = props.path
  }
  const columnHeaders = getHeaders(pageHeaders, props)

  const isChipInState = (obj, array) => {
    return array.some(
      (chip) =>
        chip.manifest.AssignmentSlot.SystemName ===
          obj.manifest.AssignmentSlot.SystemName && chip.uuid === obj.uuid,
    )
  }
  const selectChip = ({ data, assignment }) => {
    const packagedAssignment = packageAssignment({
      data,
      assignment,
      page: props.path === 'locationassignments' ? 'location' : 'patient',
      selectedStaffMember: props.selectedStaffMember,
    })

    const isLastChipOfKind =
      props.selectedStaffSlots &&
      props.selectedStaffSlots.filter(
        (chip) =>
          chip.manifest.AssignmentSlot.SystemName ===
          packagedAssignment.manifest.AssignmentSlot.SystemName,
      ).length ===
        handleSort({ key: props.sortBy, direction: props.sortDirection })
          .length -
          1

    const isSelected = isChipInState(
      packagedAssignment,
      props.selectedStaffSlots,
    )

    setCurrentlySelected(
      isSelected
        ? [
            ...currentlySelected.filter(
              (abb) =>
                abb !== packagedAssignment.manifest.AssignmentSlot.SystemName,
            ),
          ]
        : !isLastChipOfKind
          ? [
              ...currentlySelected.filter(
                (abb) =>
                  abb !== packagedAssignment.manifest.AssignmentSlot.SystemName,
              ),
            ]
          : [
              ...currentlySelected,
              packagedAssignment.manifest.AssignmentSlot.SystemName,
            ],
    )
    props.dispatch(
      setSelectedStaffSlot(
        isSelected
          ? [
              ...props.selectedStaffSlots.filter(
                (_n) =>
                  !(
                    _n.manifest.AssignmentSlot.SystemName ===
                      packagedAssignment.manifest.AssignmentSlot.SystemName &&
                    _n.uuid === packagedAssignment.uuid
                  ),
              ),
            ]
          : [...props.selectedStaffSlots, packagedAssignment],
      ),
    )
  }

  const tableHeight = useCallback(
    (height) => {
      if (props.path === 'users') {
        setHeight(height - 262)
      } else {
        setHeight(height - 297)
      }
    },
    [props.path, setHeight],
  )

  useEffect(() => {
    const handleResize = _.debounce((e) => {
      const target = e.currentTarget
        ? e.currentTarget.innerHeight
        : e.srcElement.innerHeight
      tableHeight(target)
    }, 750)

    window.addEventListener('resize', handleResize)

    // Initial call to set the height
    tableHeight(window.innerHeight)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [tableHeight])

  useEffect(() => {
    setCurrentlySelected(
      props.selectedStaffSlots &&
        props.selectedStaffSlots.reduce(
          (acc, selectedStaffSlot) =>
            acc.indexOf(
              selectedStaffSlot.manifest.AssignmentSlot.SystemName,
            ) === -1
              ? [...acc, selectedStaffSlot.manifest.AssignmentSlot.SystemName]
              : [...acc],
          [],
        ),
    )
  }, [props.selectedStaffSlots])

  // Selectors for locked roles
  const lockedRoleConfig = useSelector(
    (state) => state.groupAndStaffSlotMetaData?.unitLevelLockedRoles,
  )

  // TODO: this will change to state.groupAndStaffSlotMetaData?.siteWideLockedRoles,
  const lockedRoles = useSelector((state) => state.userGroups?.lockedRoles)

  // Memoized unit-level locked roles
  const unitLevelLockedRoles = useMemo(() => {
    return lockedRoleConfig?.unitLevelLockedRoles || []
  }, [lockedRoleConfig])

  // Combine the filtered locked roles with site-wide locked roles
  const combinedLockedRoles = useMemo(() => {
    return [...lockedRoles, ...unitLevelLockedRoles]
  }, [lockedRoles, unitLevelLockedRoles])

  const lockedHeaders = useMemo(() => {
    return columnHeaders
      ?.filter((header) =>
        combinedLockedRoles.some((role) =>
          role.lockedRoles?.includes(header.key),
        ),
      )
      .map((disabledHeader) => disabledHeader.key)
  }, [columnHeaders, combinedLockedRoles])

  useEffect(() => {
    const {
      selectedFacilityCoid,
      authToken,
      dispatch,
      sites,
      selectedUnits,
      selectedUnitIds,
    } = props

    const siteId = selectedFacilityCoid
    const selectedSite = sites?.find((site) => site.siteId === siteId)

    setCurrentlyDisabledRoles((prev) => {
      const newLockedHeaders = lockedHeaders
      if (JSON.stringify(prev) !== JSON.stringify(newLockedHeaders)) {
        return newLockedHeaders
      }
      return prev
    })

    const selectedFhirUrl = selectedUnits
      ?.filter((unit) => selectedUnitIds?.includes(unit.id))
      .map((unit) => unit.fhirResourceUrl)
    const disabledFhirUrl = unitLevelLockedRoles.map(
      (role) => role.fhirResourceUrl,
    )

    const hasMatchingUrl = selectedFhirUrl?.some(
      (url) => !disabledFhirUrl.includes(url),
    )

    if (
      !hasMatchingUrl &&
      !selectedUnitIds?.includes('00000000-0000-0000-0000-000000000000')
    ) {
      setCheckboxIsLocked(true)
    }

    if (
      selectedSite?.integrationProperties?.LockedRoles &&
      selectedSite?.integrationProperties !== null
    ) {
      dispatch(fetchLockedRoles(authToken, selectedSite.siteId))
    }
  }, [
    props.authToken,
    props.selectedFacilityCoid,
    props.sites,
    props.selectedUnits,
    props.selectedUnitIds,
    columnHeaders,
    unitLevelLockedRoles,
    combinedLockedRoles,
    lockedHeaders,
  ])
  const hasLockedCheckbox = useCallback(
    (header) => {
      if (checkboxIsLocked) {
        return combinedLockedRoles?.some(
          (role) =>
            role.lockedRoles?.includes(header) ||
            combinedLockedRoles.includes(header),
        )
      } else {
        return combinedLockedRoles?.includes(header)
      }
    },
    [checkboxIsLocked, combinedLockedRoles],
  )
  const headerColumnClickHandler = (index, header) => {
    if (props.path === 'users') {
      const selSiteId = props.selectedAdminSite
        ? props.sites.filter(
            (site) => site.value === props.selectedAdminSite?.value,
          )[0]
        : null
      const ascOrDesc = !props.sortDirection
      props.setSortDirection(ascOrDesc)
      props.setSortBy(header.key)
      props.dispatch(
        getAllUsers({
          facilityId: selSiteId ? selSiteId.facilityId : null,
          authToken: props.authToken,
          searchText: props.searchText,
          divisionId:
            props.selectedDivision && props.selectedDivision.value !== 'All'
              ? props.selectedDivision.value
              : null,
          clinicalRole: props.selectedClinicalRole
            ? props.selectedClinicalRole.systemName
            : null,
          skip: props.ui.skip,
          take: props.ui.take,
          sortBy: header.key,
          sortDirection: ascOrDesc ? 'asc' : 'desc',
        }),
      )
    } else if (index === 0) {
      props.setSortDirection(!props.sortDirection)
      props.setSortBy(header.key)
    } else if (
      (props.path === 'locations' && pageHeaders !== 'sites') ||
      (props.path === 'patientassignments' && index === 1)
    ) {
      props.setSortDirection(!props.sortDirection)
      props.setSortBy(header.key)
    }
  }

  const handleSort = ({ key, direction }) => {
    let list
    if (props.path === 'users') {
      list = props.allUsers
    } else if (props.path === 'locations') {
      if (pageHeaders === 'sites') list = props.sitesList
      if (pageHeaders === 'units') list = props.units
      if (pageHeaders === 'rooms') list = props.rooms
      if (pageHeaders === 'beds') list = props.beds
    } else if (props.path === 'patients') {
      list = props.patientList
    } else if (props.path === 'locationassignments') {
      list = props.list
      if (
        props.selectedUnitIds &&
        props.selectedUnitIds.includes('00000000-0000-0000-0000-000000000000')
      ) {
        units = props.selectedUnits
          .filter((unit) => unit)
          .sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1
            }
            if (a.displayName > b.displayName) {
              return 1
            }
            return 0
          })
      } else {
        units = props.selectedUnits
          .filter(
            (unit) =>
              props.selectedUnitIds &&
              props.selectedUnitIds.some((id) => id === unit.id),
          )
          .sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1
            }
            if (a.displayName > b.displayName) {
              return 1
            }
            return 0
          })
      }
    } else if (props.path === 'patientassignments') {
      list = props.list
      if (
        props.selectedUnitIds &&
        props.selectedUnitIds.includes('00000000-0000-0000-0000-000000000000')
      ) {
        units = props.selectedUnits
          .filter((unit) => unit)
          .sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1
            }
            if (a.displayName > b.displayName) {
              return 1
            }
            return 0
          })
      } else {
        units = props.selectedUnits
          .filter(
            (unit) =>
              props.selectedUnitIds &&
              props.selectedUnitIds.some((id) => id === unit.id),
          )
          .sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1
            }
            if (a.displayName > b.displayName) {
              return 1
            }
            return 0
          })
      }
    }
    let statusSortedList
    if (
      props.path === 'patientassignments' ||
      props.path === 'locationassignments'
    ) {
      const currentUserGroup =
        props.staffSlotsPerRoleGroup[props.selectedUserGroupName]
      const sortDirectionString = direction ? 'ASC' : 'DESC'
      statusSortedList = formatSort(
        flattenList({
          list,
          currentUserGroup,
          allGroups: props.staffSlotsPerRoleGroup,
        }),
        key === 'location' ? 'bed' : 'name',
        sortDirectionString,
        props.page.slice(0, props.page.length - 1),
      )
    } else {
      if (!key) return list
      const hash = []
      list.forEach((item) => {
        if (key === 'clinicalRole') {
          hash.push(item[key].displayName.toUpperCase())
        } else {
          hash.push(item[key]?.toUpperCase())
        }
      })

      const orderedHash = hash.sort(naturalSort)
      const orderedList = []
      for (let i = 0; i < orderedHash.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (key === 'clinicalRole') {
            if (orderedHash[i] === list[j][key].displayName.toUpperCase()) {
              orderedList.push(list[j])
            }
          } else if (orderedHash[i] === list[j][key]?.toUpperCase()) {
            orderedList.push(list[j])
          }
        }
      }
      const result = _.uniqBy(orderedList, 'id')

      if (key !== 'status') {
        if (direction) {
          statusSortedList = result
        } else {
          statusSortedList = result.reverse()
        }
      }
    }
    if (props.path === 'users') {
      return list
    } else {
      return statusSortedList
    }
  }
  let showLoading
  if (props.path === 'users') {
    showLoading = props.fetchingUsers
  } else if (props.path === 'locations') {
    showLoading =
      props.fetchingSiteMetaData ||
      props.fetchingMetaData ||
      props.fetchingRoomMetadata ||
      props.fetchingLockedRoles
  } else if (props.path === 'patients') {
    showLoading = props.patientLoading || props.fetchingLockedRoles
  }
  const sortedList = handleSort({
    key: props.sortBy,
    direction: props.sortDirection,
  })

  const handleSortingHeaders = (index, header) => {
    if (header.label === 'SITE') {
      return
    } else if (header.label === 'SITE ACCESS' || header.label === '') {
      return
    } else {
      headerColumnClickHandler(index, header)
    }
  }

  const showHideSlidingPaneClassName = props.ui.assignmentSlidingPane.open
    ? 'modal display-block'
    : 'modal display-block'

  return (
    !showLoading && (
      <NeuCard
        style={{
          display: 'flex',
          width: '100%',
          height: 'calc(100vh - 200px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {showPane && (
          <PatientSlidingPane
            hideSlidingPane={() =>
              props.dispatch({
                type: 'ui/ASSIGNMENT_SLIDING_PANE',
                payload: {
                  open: false,
                  source: '',
                  data: props.ui.assignmentSlidingPane.data,
                  userExists: false,
                },
              })
            }
            showHideClassName={showHideSlidingPaneClassName}
            slidingPane={props.ui.assignmentSlidingPane}
          />
        )}
        <NeuTable
          key={`${props.path}-${new Date().getTime()}`}
          kind="default"
          sticky="header"
          size="default"
          caption=""
          style={{
            display: 'flex',
            width: '100%',
            overflowX: 'visible',
          }}
        >
          <NeuTable unit="header">
            <NeuTable unit="row">
              {!props.fetchingLockedRoles &&
                columnHeaders.map((header, index) => (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      minWidth:
                        props.path === 'locationassignments' && index === 0
                          ? '180px'
                          : props.path === 'patientassignments' && index === 0
                            ? '185px'
                            : props.path === 'patientassignments' && index === 1
                              ? '185px'
                              : undefined,
                    }}
                    key={`${header.key}-${index}`}
                  >
                    <NeuTable
                      unit="column"
                      header={true}
                      key={index}
                      sort={
                        props.path === 'locationassignments' && index === 0
                          ? true
                          : props.path === 'patientassignments' && index === 0
                            ? true
                            : props.path === 'patientassignments' && index === 1
                              ? true
                              : props.path === 'users' && index === 0
                                ? true
                                : props.path === 'users' && index === 1
                                  ? true
                                  : props.path === 'locations' && index === 0
                                    ? true
                                    : props.path === 'patients' && index === 0
                                      ? true
                                      : false
                      }
                      direction={
                        (props.path === 'users' &&
                          header.label !== 'SITE' &&
                          header.label !== 'SITE ACCESS') ||
                        (header.label === 'BED' &&
                          props.path === 'patientassignments')
                          ? props.sortBy === header.key && !props.sortDirection
                            ? 'desc'
                            : 'asc'
                          : index === 0
                            ? props.sortBy === header.key &&
                              !props.sortDirection
                              ? 'desc'
                              : 'asc'
                            : ''
                      }
                      onNeuSort={() => {
                        if (header.label !== 'STATUS')
                          handleSortingHeaders(index, header)
                      }}
                    >
                      <div
                        style={{
                          minWidth:
                            (props.path === 'locationassignments' &&
                              index === 0) ||
                            (props.path === 'patientassignments' && index === 1)
                              ? '100%'
                              : props.path === 'locationassignments' &&
                                  index === 1
                                ? '199px'
                                : props.path === 'patientassignments' &&
                                    index === 0
                                  ? '100%'
                                  : (props.path === 'locationassignments' &&
                                        index >= 2 &&
                                        index <= 5) ||
                                      (props.path === 'patientassignments' &&
                                        index >= 2 &&
                                        index <= 5)
                                    ? '140px'
                                    : index === 6
                                      ? '93px'
                                      : '100%',
                          display: 'inline-flex',
                          justifyContent:
                            (props.path === 'locationassignments' ||
                              props.path === 'patientassignments') &&
                            index >= 2 &&
                            index <= 5
                              ? 'center'
                              : 'undefined',
                          alignItems: 'center',
                          whiteSpace:
                            (props.path === 'locationassignments' ||
                              props.path === 'patientassignments') &&
                            index === 6
                              ? 'normal'
                              : undefined,
                          overflowWrap:
                            (props.path === 'locationassignments' ||
                              props.path === 'patientassignments') &&
                            index === 6
                              ? 'normal'
                              : undefined,
                          wordBreak:
                            (props.path === 'locationassignments' ||
                              props.path === 'patientassignments') &&
                            index === 6
                              ? 'normal'
                              : undefined,
                        }}
                      >
                        {header.id && (
                          <div
                            style={{
                              paddingTop: '5px',
                              paddingRight: '5px',
                            }}
                          >
                            {lockedHeaders.length > 0 &&
                            lockedHeaders.includes(header.key) ? (
                              <NeuCheckbox
                                disabled={hasLockedCheckbox(header.key)}
                                style={{
                                  background: '#bcbcbc',
                                  width: '14px',
                                  height: '14px',
                                  marginRight: '2px',
                                  borderRadius: '0px',
                                }}
                              />
                            ) : (
                              <NeuCheckbox
                                color="blue"
                                onNeuForm={() => {
                                  if (
                                    currentlySelected.some(
                                      (selectedKey) =>
                                        selectedKey === header.key,
                                    )
                                  ) {
                                    if (!props.stagedStaffSlots.length > 0) {
                                      props.dispatch({
                                        type: 'ui/TABLE_CHECKBOX_CHECKED',
                                        payload: false,
                                      })
                                    }
                                    setCurrentlySelected([
                                      ...currentlySelected.filter(
                                        (selKey) => selKey !== header.key,
                                      ),
                                    ])
                                    props.dispatch(
                                      setSelectedStaffSlot([
                                        ...props.selectedStaffSlots.filter(
                                          (_n) =>
                                            _n.manifest.AssignmentSlot
                                              .SystemName !== header.key,
                                        ),
                                      ]),
                                    )
                                  } else {
                                    // Dispatch an action to indicate that the table checkbox has been checked
                                    props.dispatch({
                                      type: 'ui/TABLE_CHECKBOX_CHECKED',
                                      payload: true,
                                    })
                                    // Initialize an empty array to hold the selected assignments
                                    const selections = []
                                    // Get the key for the current header
                                    const dataKey = header.key
                                    // Iterate over the sorted list of locations
                                    sortedList.forEach((_location) => {
                                      // Package the assignment data for the current location
                                      const packagedAssignment =
                                        packageAssignment({
                                          data: _location,
                                          assignment: _location[dataKey],
                                          page:
                                            props.path === 'locationassignments'
                                              ? 'location'
                                              : 'patient',
                                          selectedStaffMember:
                                            props.selectedStaffMember,
                                        })

                                      // Check if the packaged assignment is already in the selected staff slots
                                      const isAlreadyInState = isChipInState(
                                        packagedAssignment,
                                        props.selectedStaffSlots,
                                      )

                                      // If the assignment is not already in the selected staff slots and isn't locked, add it to the selections array
                                      if (!isAlreadyInState) {
                                        selections.push(packagedAssignment)
                                      }
                                    })

                                    setCurrentlySelected([
                                      ...currentlySelected,
                                      header.key,
                                    ])
                                    props.dispatch(
                                      setSelectedStaffSlot([
                                        ...props.selectedStaffSlots,
                                        ...selections,
                                      ]),
                                    )
                                  }
                                }}
                                custom={false}
                                isSelected={
                                  sortedList.length > 0 &&
                                  props.selectedStaffSlots.filter(
                                    (chip) =>
                                      chip.manifest.AssignmentSlot
                                        .SystemName === header.key,
                                  ).length === sortedList.length &&
                                  currentlySelected.includes(header.key)
                                }
                                disabled={hasLockedCheckbox(header.key)}
                              />
                            )}
                          </div>
                        )}
                        <div
                          style={{
                            color:
                              props.sortBy === header.key
                                ? '#03173e'
                                : '#58595B',
                            fontSize: '14px',
                            fontFamily: 'HCA-Mark',
                            fontWeight: '500',
                            paddingTop: '5px',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            ...(header.label === 'ASSIGNED TO BED' ||
                            header.label === 'ASSIGNED TO PATIENT'
                              ? {
                                  textAlign: 'center',
                                }
                              : {}),
                          }}
                        >
                          {header.label}
                        </div>
                      </div>
                    </NeuTable>
                  </div>
                ))}
            </NeuTable>
          </NeuTable>
          <NeuTable unit="content">
            {props.patientList &&
            props.patientList.length < 1 &&
            props.path === 'patients' ? (
              <div
                className="neu-text-body neu-text-body fw-bold neu-text-gray-60"
                style={{ paddingLeft: '20px', paddingTop: '10px' }}
              >
                {props.selectedUnit
                  ? 'No patients found in this unit'
                  : 'Please select a unit.'}
              </div>
            ) : showLoading ? (
              <Loading square="100px" />
            ) : !sortedList && pageHeaders === 'beds' ? (
              <div
                className="neu-text-body neu-text-body fw-bold neu-text-gray-60"
                style={{ paddingLeft: '20px' }}
              >
                No beds found in this room
              </div>
            ) : !sortedList && pageHeaders === 'rooms' ? (
              <div
                className="neu-text-body neu-text-body fw-bold neu-text-gray-60"
                style={{ paddingLeft: '20px' }}
              >
                No rooms found in this unit
              </div>
            ) : !sortedList && pageHeaders === 'units' ? (
              <div
                className="neu-text-body neu-text-body fw-bold neu-text-gray-60"
                style={{ paddingLeft: '20px' }}
              >
                No units found in this site
              </div>
            ) : !sortedList && pageHeaders === 'sites' ? (
              <div
                className="neu-text-body neu-text-body fw-bold neu-text-gray-60"
                style={{ paddingLeft: '20px' }}
              >
                No sites found in this division
              </div>
            ) : (
              <div>
                {props.path === 'locationassignments' ||
                props.path === 'patientassignments'
                  ? units.map((unit) => {
                      return (
                        !showLoading && (
                          <span key={unit.id}>
                            <styles.UnitContainer>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <styles.UnitName>
                                  {unit.displayName}
                                </styles.UnitName>
                                {props.draftAssignments.draftIsLoading ? (
                                  <SmallLoading square="20px" />
                                ) : (
                                  props.draftAssignments.length > 0 &&
                                  props.draftAssignments[0].department ===
                                    props.ui.departmentSelected &&
                                  props.ui.isDraftAssignment &&
                                  props.path === 'locationassignments' &&
                                  props.draftAssignments
                                    .filter(
                                      (assignment) =>
                                        assignment.unitId === unit.id,
                                    )
                                    .map((assignment) => (
                                      <styles.DraftSaved
                                        className="neu-text-caption"
                                        key={assignment.id}
                                        style={{
                                          fontWeight: 'normal',
                                          padding: '10px 10px 10px 10px',
                                          width: '100%',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        Draft saved{' '}
                                        {moment(assignment.modifiedOn).format(
                                          'MMMM Do YYYY, h:mm:ss a',
                                        )}{' '}
                                        by {assignment.modifiedBy}
                                      </styles.DraftSaved>
                                    ))
                                )}
                              </div>

                              {sortedList.filter(
                                (item) => item.location.unitId === unit.id,
                              ).length > 0 &&
                              !props.fetchingLocations &&
                              !props.fetchingUsers &&
                              !props.fetchingLockedRoles ? (
                                <span>
                                  <DataList
                                    columns={columnHeaders}
                                    disabled={combinedLockedRoles}
                                    handleLocationsTableSelection={
                                      props.handleLocationsTableSelection
                                    }
                                    selectedSiteId={props.selectedSiteId}
                                    list={sortedList.filter(
                                      (item) =>
                                        item.location.unitId === unit.id,
                                    )}
                                    props={{ ...props }}
                                    style={{ overflow: 'scroll' }}
                                    path={pageHeaders}
                                    authToken={props.authToken}
                                    coid={props.coid}
                                    ui={props.ui}
                                    statusDropDownOptions={
                                      props.statusDropDownOptions
                                    }
                                    sites={props.sites}
                                    formattedSitesForDropdown={
                                      props.formattedSitesForDropdown
                                    }
                                    authorizedGlobalAdmin={
                                      props.authorizedGlobalAdmin
                                    }
                                    authorizedLocalAdmin={
                                      props.authorizedLocalAdmin
                                    }
                                    dispatch={props.dispatch}
                                    staffSlots={props.staffSlots}
                                    selectedUserGroupName={
                                      props.selectedUserGroupName
                                    }
                                    selectChip={selectChip}
                                    selectedStaffSlots={
                                      props.selectedStaffSlots
                                    }
                                    stagedStaffSlots={props.stagedStaffSlots}
                                  />
                                </span>
                              ) : (
                                <styles.NoDataMessage>
                                  NO{' '}
                                  {props.path === 'locationassignments'
                                    ? 'BEDS'
                                    : 'PATIENTS'}{' '}
                                  FOUND IN UNIT {unit.displayName}
                                </styles.NoDataMessage>
                              )}
                            </styles.UnitContainer>
                          </span>
                        )
                      )
                    })
                  : !props.fetchingUsers && (
                      <span>
                        <DataList
                          columns={columnHeaders}
                          handleLocationsTableSelection={
                            props.handleLocationsTableSelection
                          }
                          selectedAdminSite={props.selectedAdminSite}
                          selectedSiteId={props.selectedSiteId}
                          list={sortedList}
                          props={{ ...props }}
                          style={{ overflow: 'scroll' }}
                          path={pageHeaders}
                          authToken={props.authToken}
                          coid={props.coid}
                          ui={props.ui}
                          statusDropDownOptions={props.statusDropDownOptions}
                          sites={props.sites}
                          formattedSitesForDropdown={
                            props.formattedSitesForDropdown
                          }
                          authorizedGlobalAdmin={props.authorizedGlobalAdmin}
                          authorizedLocalAdmin={props.authorizedLocalAdmin}
                          dispatch={props.dispatch}
                          staffSlots={props.staffSlots}
                          selectedUserGroupName={props.selectedUserGroupName}
                          selectChip={selectChip}
                          selectedStaffSlots={props.selectedStaffSlots}
                          stagedStaffSlots={props.stagedStaffSlots}
                        />
                      </span>
                    )}
              </div>
            )}
            {props.path === 'users' && (
              <styles.PaginationContainer>
                {props.ui.skip !== 0 && (
                  <styles.PaginationLink
                    onClick={() => {
                      props.dispatch({
                        type: 'user/SET_PAGINATION',
                        payload: {
                          skip: props.ui.skip - 50,
                          take: props.ui.take - 50,
                        },
                      })
                    }}
                  >
                    {' '}
                    Previous Page{' '}
                  </styles.PaginationLink>
                )}
                {props.ui.skip !== 0 && props.allUsers.length === 50 && (
                  <div style={{ paddingRight: '5px' }}> / </div>
                )}
                {props.allUsers.length === 50 && (
                  <span>
                    <styles.PaginationLink
                      onClick={() => {
                        props.dispatch({
                          type: 'user/SET_PAGINATION',
                          payload: {
                            skip: props.ui.skip + 50,
                            take: props.ui.take + 50,
                          },
                        })
                      }}
                    >
                      {' '}
                      Next Page
                    </styles.PaginationLink>
                  </span>
                )}
              </styles.PaginationContainer>
            )}
          </NeuTable>
        </NeuTable>
      </NeuCard>
    )
  )
}

export default connect()(Table)
