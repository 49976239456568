import moment from 'moment'

export default function formatReports(report) {
  if (!Array.isArray(report)) return []
  if (!(report.length > 0)) return []

  let type = report[0].hasOwnProperty('careTeam')
    ? 'snapshot'
    : report[0].hasOwnProperty('missingSlot')
      ? 'missing'
      : 'standard'
  const headers = getHeaders(report)

  return {
    report:
      type === 'snapshot'
        ? getSnapshot(report)
        : type === 'missing'
          ? getMissingAssignments(report)
          : getStandard(report),
    headers: mapHeaders(headers),
  }
}

function mapHeaders(headers) {
  const mappings = [
    { key: 'unit', label: 'Unit' }, // All
    { key: 'bed', label: 'Bed' }, // All
    { key: 'dateOfBirth', label: 'Patient DOB' }, // All
    { key: 'gender', label: 'Patient Gender' }, // All
    { key: 'mrn', label: 'Patient MRN' }, // All
    { key: 'patientName', label: 'Patient Name' }, // All
    { key: 'isolationStatus', label: 'Isolation Status' }, // All
    { key: 'assignedPosition', label: 'Care Team Assigned Position' }, // Snapshot
    { key: 'assignedStaffMember', label: 'Care Team Assigned Staff' }, // Snapshot
    { key: 'lastUpdated', label: 'Team Last Updated (Time)' }, // Snapshot
    { key: 'modifiedOn', label: 'Assignment Filled' }, // Missing Assignments
    { key: 'missingSince', label: 'Missing Since' }, // Missing Assignments
    { key: 'missingSlot', label: 'Missing' }, // Missing Assignments
    { key: 'action', label: 'Action' }, // Assignment Audit
    { key: 'assignment34', label: 'Staff 34ID' }, // Assignment Audit
    { key: 'time', label: 'Date Time' }, // Assignment Audit
    { key: 'assignmentType', label: 'Assignment Type' }, // Assignment Audit
    { key: 'staffMember', label: 'Staff Name' }, // Assignment Audit
    { key: 'madeBy', label: 'Made By Name' }, // Assignment Audit
    { key: 'user34', label: 'Made By 34ID' }, // Assignment Audit
    { key: 'position', label: 'Assigned Position' }, // Assignment Audit
  ]

  return mappings.filter((item) =>
    headers.some((heading) => heading === item.key),
  )
}

function stripMeta(meta) {
  const split = meta && meta.split(' ')
  return split.length > 0
    ? {
        gender: split[0],
        mrn: split[4],
        dateOfBirth: split[2],
        isolationStatus: split[5],
      }
    : {
        gender: '',
        mrn: '',
        dateOfBirth: '',
        isolationStatus: '',
      }
}

function getHeaders(report) {
  const headers = []
  if (report.length > 0) {
    // Only do this code if a report exists
    for (var prop in report[0]) {
      // we only care about mapping headers from THIS row in the data
      if (prop === 'careTeam') {
        // only found in 'snapshot'
        headers.push(...['assignedPosition', 'assignedStaffMember'])
      } else if (prop === 'patientMeta') {
        // found outside of 'snapshot'
        headers.push(...['gender', 'dateOfBirth', 'mrn'])
      } else {
        // standard header
        headers.push(prop)
      }
    }
  }
  return headers
}
function getFullName(str) {
  const split = str.split(', ')
  if (split[0] && split[1]) {
    return split[1] + ' ' + split[0]
  } else {
    return str
  }
}

function getSnapshot(report) {
  const snapshot = []

  for (var i = 0; i < report.length; i++) {
    const { gender, dateOfBirth, mrn } = stripMeta(report[i].patientMeta)
    const base = {
      unit: report[i].unit,
      bed: ' ' + report[i].bed,
      patientName: getFullName(report[i].patientName),
      gender,
      dateOfBirth,
      mrn,
      isolationStatus: report[i].isolationStatus,
      lastUpdated: report[i].lastUpdated,
    }
    report[i].careTeam &&
      report[i].careTeam.forEach((member) => {
        const row = Object.assign({}, base, {
          assignedPosition: member.slotAbbreviation,
          assignedStaffMember: member.teamMember,
        })
        if (base.patientName !== '') {
          snapshot.push(row)
        }
      })
    if (report[i].careTeam.length < 1 && base.patientName !== '') {
      snapshot.push(base)
    }
  }
  return snapshot
}

function getStandard(report) {
  const standard = []
  for (var i = 0; i < report.length; i++) {
    const { gender, dateOfBirth, mrn } = stripMeta(report[i].patientMeta)
    const base = {
      unit: report[i].unit,
      bed: ' ' + report[i].bed,
      patientName: getFullName(report[i].patientName),
      gender,
      dateOfBirth,
      mrn,
      lastUpdated: report[i].lastUpdated,
    }

    if (
      report[i].hasOwnProperty('madeBy') &&
      report[i].hasOwnProperty('staffMember')
    ) {
      base.madeBy = getFullName(report[i].madeBy)
      base.staffMember = getFullName(report[i].staffMember)
    }

    standard.push(Object.assign({}, report[i], base))
  }
  return standard
}

function getMissingAssignments(report) {
  const missing = []
  for (var i = 0; i < report.length; i++) {
    const { gender, dateOfBirth, mrn } = stripMeta(report[i].patientMeta)
    const base = {
      unit: report[i].unit,
      bed: ' ' + report[i].bed,
      patientName: getFullName(report[i].patientName),
      gender,
      dateOfBirth,
      mrn,
      isolationStatus: report[i].isolationStatus,
      lastUpdated: report[i].lastUpdated,
    }

    if (
      report[i].hasOwnProperty('madeBy') &&
      report[i].hasOwnProperty('staffMember')
    ) {
      base.madeBy = getFullName(report[i].madeBy)
      base.staffMember = getFullName(report[i].staffMember)
    }

    missing.push(Object.assign({}, report[i], base))
  }
  return missing
}

export const formatSnapshotData = (snapshotData, positions = []) => {
  const formattedSnapshotData = []
  snapshotData.forEach((data) => {
    const patientName =
      data.patient !== null
        ? `${data.patient.lastName}, ${data.patient.firstName}`
        : ''
    const patientMeta =
      data.patient !== null
        ? `${data.patient.gender.charAt(0)} | ${moment(
            data.patient.dateOfBirth,
          ).format('MM/DD/YYYY')} | ${data.patient.medicalRecordNumber}`
        : ''
    const isolationStatus =
      data.patient !== null ? `${data.patient.isolationStatus}` : ''
    const lastUpdated = moment(data.facilityLocalModifiedOn).format(
      'MM/DD/YYYY HH:mm',
    )
    const assignmentFunction = (assignments, _positions) => {
      const filteredAssignments = []
      if (_positions.length > 0) {
        _positions.forEach((position) => {
          assignments &&
            assignments.forEach(({ assignedSlot, user }) => {
              if (assignedSlot.abbreviation === position) {
                filteredAssignments.push({
                  teamMember: `${user?.firstName} ${user?.lastName.charAt(0)}.`,
                  slotAbbreviation: assignedSlot.abbreviation,
                  slotSystemName: assignedSlot.systemName,
                })
              }
            })
        })
      } else {
        assignments &&
          assignments.forEach(({ assignedSlot, user }) => {
            filteredAssignments.push({
              teamMember: `${user?.firstName} ${user?.lastName.charAt(0)}.`,
              slotAbbreviation: assignedSlot.abbreviation,
              slotSystemName: assignedSlot.systemName,
            })
          })
      }
      return filteredAssignments
    }

    const allAssignments = [
      ...assignmentFunction(data.location.assignments, positions),
      ...(data.patient
        ? assignmentFunction(data.patient.assignments, positions)
        : []),
    ]
    if (patientName !== '') {
      formattedSnapshotData.push({
        id: data.id,
        unit: data.location.unit.displayName,
        bed: data.location.displayName,
        patientName,
        patientMeta,
        isolationStatus: data.patient.isolationStatus,
        careTeam: allAssignments,
        lastUpdated,
      })
    }
  })

  return formattedSnapshotData
}

export const formatAssignmentAuditReport = (
  auditData,
  allFormattedPositions,
) => {
  const formattedAuditData = []
  auditData.forEach((data) => {
    const id = data.id
    const time = moment(data.facilityLocalAssignedTime).format(
      'MM/DD/YYYY HH:mm',
    )
    const patientName =
      data.patient !== null
        ? `${data.patient.lastName}, ${data.patient.firstName}`
        : ''
    const patientMeta =
      data.patient !== null
        ? `${data.patient.gender.charAt(0)} | ${moment(
            data.patient.dateOfBirth,
          ).format('MM/DD/YYYY')} | ${data.patient.medicalRecordNumber}`
        : ''
    const action = data.action === 'Delete' ? 'Unassigned' : 'Assigned'
    const slotAssigned = allFormattedPositions.filter(
      (position) => position.value === data.assignmentSlotSystemName,
    )
    const position = `${data.assignmentType} - ${
      slotAssigned[0] ? slotAssigned[0].text : ''
    }`
    const staffMember = `${data.staff.lastName}, ${data.staff.firstName}`
    const assignment34 = data.staff.hca34.toUpperCase()
    const madeBy = data.modifiedBy
      ? `${data.modifiedBy.lastName}, ${data.modifiedBy.firstName}`
      : ''
    const user34 = data.modifiedBy ? data.modifiedBy.hca34.toUpperCase() : ''

    formattedAuditData.push({
      id,
      time,
      unit: data.location.unit.displayName,
      bed: data.location.displayName,
      patientName,
      patientMeta,
      action,
      position,
      staffMember,
      assignment34,
      madeBy,
      user34,
    })
  })
  return formattedAuditData
}

export const formatMissingAssignmentReport = (reportData, site) => {
  const formattedMissingAssignmentData = []

  let assignmentDataToFormat = reportData
  assignmentDataToFormat.forEach((report) => {
    formattedMissingAssignmentData.push({
      //create new id with missingSlot for sorting since ids are duplicated
      id: report.id + report.missingSlot,
      unit: report.unitName ? report.unitName : '',
      bed: report.locationName ? report.locationName : '',
      patientName:
        report.lastName && report.firstName
          ? `${report.lastName}, ${report.firstName}`
          : '',
      patientMeta:
        report.gender && report.dateOfBirth && report.medicalRecordNumber
          ? `${report.gender.charAt(0)} | ${moment(report.dateOfBirth).format(
              'MM/DD/YYYY',
            )} | ${report.medicalRecordNumber}`
          : '',
      isolationStatus: report.isolationStatus,
      modifiedOn: report.wasCompletedByDischarged
        ? 'Discharged'
        : report.facilityLocalModifiedOn
          ? moment(report.facilityLocalModifiedOn).format('MMMM DD, YYYY HH:mm')
          : '',
      missingSince: report.facilityLocalMissingSince
        ? moment(report.facilityLocalMissingSince).format('MMMM DD, YYYY HH:mm')
        : '',
      missingSlot: report.missingSlot ? report.missingSlot : '',
    })
  })

  return formattedMissingAssignmentData
}
