/**
 * Internal Imports
 * */
import Flex from './styleLibrary'
import IsolationStatusBadge from '../table/IsolationStatusBadge'
/**
 * Styling Imports
 * */
import { Chip, Bubble } from './neutronComponents/chip/chip'

const PrintLocations = (data, props) => {
  const locationChipSlots = props.props.staffSlots.map((slot) => {
    const assignment = data[slot.systemName]

    // Error check
    if (!assignment || !assignment.systemName) {
      console.error('Assignment or systemName is undefined:', assignment)
      return null
    }

    // Check if the assignment is disabled
    const isDisabled = props.disabled.some((item) => {
      const isMatch =
        (data.location.unitFhirResourceUrl === item.fhirResourceUrl &&
          item.lockedRoles.includes(assignment.systemName)) ||
        props.disabled.some((chip) => chip === slot.systemName)
      return isMatch
    })

    const stagedStaffSlot =
      props.stagedStaffSlots &&
      props.stagedStaffSlots.length > 0 &&
      props.stagedStaffSlots.filter(
        (slot) =>
          assignment.systemName === slot.manifest.AssignmentSlot.SystemName &&
          slot.uuid === data.uuid,
      )[0]

    const assignedUser = stagedStaffSlot
      ? stagedStaffSlot &&
        stagedStaffSlot.assignedToLocation &&
        stagedStaffSlot &&
        stagedStaffSlot.assignedToLocation.user
      : assignment &&
        assignment.assignedToLocation &&
        assignment &&
        assignment.assignedToLocation.user

    const currentRowFromState = props.list.filter((row) => {
      // if list has a user assigned to this role
      return row.uuid === data.uuid
    })[0]

    const currentUserFromState =
      currentRowFromState[slot.systemName] &&
      currentRowFromState[slot.systemName].assignedToLocation &&
      currentRowFromState[slot.systemName].assignedToLocation.user

    const isFreshAssignment = !(
      currentUserFromState && currentUserFromState.length > 0
    )

    if (props.ui.isDraftAssignment) {
      return (
        <Chip
          style={{
            paddingRight: '100px',
            background: 'pink',
          }}
          action={
            (assignment &&
              assignment.assignedToLocation &&
              assignment &&
              assignment.assignedToLocation.user) ||
            assignedUser
              ? 'assign'
              : false
          }
          staged={stagedStaffSlot}
          isFreshAssignment={true}
          assignedUser={assignedUser}
          assignmentText={
            (assignedUser && assignedUser) ||
            (assignment && assignment.abbreviation)
          }
          roleText={assignment.abbreviation}
          selected={
            props.selectedStaffSlots.length > 0 &&
            props.selectedStaffSlots.filter(
              (chip) =>
                chip.manifest.AssignmentSlot &&
                chip.manifest.AssignmentSlot.SystemName ===
                  (assignment && assignment.systemName) &&
                chip.uuid === data.uuid,
            ).length > 0
          }
          isDisabled={isDisabled}
          click={() => {
            props.selectChip({ data, assignment })
          }}
          context={data.rowIndex === props.list.length - 1 ? 'last' : ''}
        />
      )
    } else {
      return (
        <Chip
          style={{
            paddingRight: '100px',
            background: 'pink',
          }}
          action={stagedStaffSlot && stagedStaffSlot.action}
          staged={stagedStaffSlot}
          isFreshAssignment={isFreshAssignment}
          assignedUser={assignedUser}
          assignmentText={
            (assignedUser && assignedUser) ||
            (assignment && assignment.abbreviation)
          }
          roleText={assignment.abbreviation}
          selected={
            props.selectedStaffSlots.length > 0 &&
            props.selectedStaffSlots.filter(
              (chip) =>
                chip.manifest.AssignmentSlot &&
                chip.manifest.AssignmentSlot.SystemName ===
                  (assignment && assignment.systemName) &&
                chip.uuid === data.uuid,
            ).length > 0
          }
          isDisabled={isDisabled}
          click={() => {
            props.selectChip({ data, assignment })
          }}
          context={data.rowIndex === props.list.length - 1 ? 'last' : ''}
        />
      )
    }
  })

  const patientAssignmentBubbles = []
  props.staffSlots.forEach((_slot) => {
    if (
      data[_slot.systemName] &&
      data[_slot.systemName].assignedToPatient.user
    ) {
      patientAssignmentBubbles.push(data[_slot.systemName].abbreviation)
    } else {
      patientAssignmentBubbles.push('___')
    }
  })

  const formattedData = [
    <div
      style={{
        fontWeight: 'bold',
        fontFamily: 'HCA-Mark',
        width: '175px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        top: '50%',
      }}
      className="neu-text-body fw-bold neu-text-gray90"
    >
      {data.location.bed}
    </div>,
    <Flex
      style={{
        fontSize: 12,
        height: 'max-content',
        color: '#1F2532',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '180px',
      }}
      direction="column"
    >
      <span
        style={{
          fontWeight: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {data.patient.name}
      </span>
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'normal',
          paddingTop: '5px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {data.patient.meta}
        <IsolationStatusBadge
          isolationStatusList={data.patient.isolationStatus}
          keyProp={data.patient.id}
        />
      </span>
    </Flex>,
    <Flex style={{ marginTop: 20, width: '100%', position: 'absolute' }}>
      {props.staffSlots.map((_slot, index) => {
        return (
          <Bubble
            text={patientAssignmentBubbles[index]}
            key={data.uuid + index}
          />
        )
      })}
    </Flex>,
  ]
  for (let i = 0; i < locationChipSlots.length; i++) {
    const itemIndex = 2 + i
    formattedData.splice(itemIndex, 0, locationChipSlots[i])
  }
  return formattedData
}

export default PrintLocations
